import { Card, Link, LoadingIndicator } from "@optum-osgp-temp/osgp-ui-component-lib";
import React, { useCallback, useEffect, useState } from 'react';
import { ContentType, OSGPNewsAndAnnouncementsProps } from './OSGPNewsAndAnnouncementsProps';

const containerNodeClassName = "osgp-news-content-container";

import './styles.scss';
import { OSGPNewsAndAnnouncementsListItem } from "./OSGPNewsAndAnnouncementsListItem";
import { apiCall, getHeaders } from "../../utilities/serviceUtils";
import { OSGPNotesTasksErrorLoading } from "../OSGPNotesTasksErrorLoading";
import { OSGPNotesTasksEmpty } from "../OSGPNotesTasksEmpty";

export const OSGPNewsAndAnnouncements = ({
    className,
    apiConfig,
    locale,
    type,
    title,
    ...otherProps
}: OSGPNewsAndAnnouncementsProps) => {

    //Common states
    const [success, setSuccess] = useState(true);
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList]: any = useState([]);
    
    const checkAndAppend = (queryParam : string, key: string, value : any) => {
        if (value?.trim().length > 0) {
            if(queryParam.trim().length > 0) {
                queryParam = queryParam + "&"
            }
            queryParam = queryParam + key+"="+value.trim();
        }
        return queryParam;
    }

    const getQueryParam = (appAiConfig: any) => {
        let queryParam = "";
        if(appAiConfig !== undefined) {
            queryParam = checkAndAppend(queryParam, "currentRoleName", appAiConfig.currentRoleName);
            queryParam = checkAndAppend(queryParam, "subCategory", appAiConfig.subCategory);
        }
        if(queryParam.length > 0) {
            queryParam = "?" + queryParam;
        }
        return queryParam;
    }

    const getData = useCallback(() => {
        setLoading(true);
        setSuccess(false);
        let contextPath = '/ui-config/content/';
        if (type === ContentType.Announcements) {
            contextPath = contextPath + 'Announcements';
        } else {
            contextPath = contextPath + 'News';
        }
        
        contextPath = contextPath + getQueryParam(apiConfig.requestParam);
        apiCall({
            baseUrl: apiConfig.baseUrl,
            route: contextPath,
            method: 'get',
            headers: getHeaders(apiConfig.header),
        }).then(({ isError, json, status }) => {

            setLoading(false);
            if (isError || status !== 200) {
                setDataList([]);
                setSuccess(false);
                setError(true);
                return;
            }
            setSuccess(true);
            setError(false);
            setDataList(json);
        });
    }, [className, apiConfig, locale]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <div className="col-s-12 col-m-12 col-l-6">
                <Card
                    className={`${containerNodeClassName + (className ? (" " + className) : "")}`}
                    header={<h1 className="heading">{title}</h1>}
                >
                    {loading &&
                        <LoadingIndicator
                            className='news-loader'
                            loading={loading}
                            displayOverlay={true}
                            size={"m"}
                            centerSpinner={true}
                            accessibilityText={"Loading, please wait..."}
                            loadingText={"Loading, please wait..."}
                        />
                    }
                    {!loading && error ? (
                        <OSGPNotesTasksErrorLoading onReload={getData} />
                    ) : !loading && success && !dataList?.length ? (
                        <OSGPNotesTasksEmpty type={title} />
                    ) : null}


                    {!loading && success && dataList?.length > 0 ? (dataList.map((listItemData: any, index: number) => (

                        <OSGPNewsAndAnnouncementsListItem key={`content-${type}-${index}`} locale={locale} data={listItemData} baseURL={apiConfig.baseUrl} index={index % 2} type={type} />
                    ))) : null}

                </Card>
            </div>
        </>
    );
};
