var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TextField, Autocomplete, createFilterOptions, } from "@optum-osgp-temp/osgp-ui-component-lib";
import React, { useState } from "react";
import "./styles.scss";
import * as _ from "lodash";
export default function OSGPAutocompleteGrid(props) {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var optionsList = ((_a = props.options) === null || _a === void 0 ? void 0 : _a.optionsList) || [];
    var searchKey = ((_b = props.options) === null || _b === void 0 ? void 0 : _b.searchKey) || "label";
    var inputLabelKey = ((_c = props.options) === null || _c === void 0 ? void 0 : _c.inputLabelKey) || "label";
    var col1Header = ((_d = props.options) === null || _d === void 0 ? void 0 : _d.col1Header) || "NPI";
    var col2Header = ((_e = props.options) === null || _e === void 0 ? void 0 : _e.col2Header) || "PROVIDER DETAILS";
    var col1Key = ((_f = props.options) === null || _f === void 0 ? void 0 : _f.col1Key) || "npi";
    var col2Key = ((_g = props.options) === null || _g === void 0 ? void 0 : _g.col2Key) || "providerInformation.name";
    var col2SubHeadingKey = ((_h = props.options) === null || _h === void 0 ? void 0 : _h.col2SubHeadingKey) || "providerInformation.address";
    var apiEndpoint = (_j = props.options) === null || _j === void 0 ? void 0 : _j.apiEndpoint;
    var _m = useState(props.value || ""), inputValue = _m[0], setInputValue = _m[1];
    var _o = useState(null), value = _o[0], setValue = _o[1];
    var _p = useState(false), open = _p[0], setOpen = _p[1];
    var _q = React.useState(optionsList), options = _q[0], setOptions = _q[1];
    var loading = open && options.length === 0;
    React.useEffect(function () {
        var active = true;
        if (!loading) {
            return undefined;
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (active) {
                    fetchOptions();
                }
                return [2 /*return*/];
            });
        }); })();
        return function () {
            active = false;
        };
    }, [loading]);
    var fetchOptions = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var response, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!apiEndpoint) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(apiEndpoint)];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    res = _a.sent();
                    setOptions(res);
                    return [2 /*return*/, res];
                case 4:
                    error_1 = _a.sent();
                    throw new Error(error_1.message);
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    // For highlighting the matching text
    function highlightMatchingText(option, value) {
        var optionStringify = _.toString(option);
        var valueStringify = _.toString(value);
        var index = optionStringify
            .toLowerCase()
            .indexOf(valueStringify.toLowerCase());
        if (index >= 0)
            return (React.createElement("span", null,
                React.createElement("span", { className: "list-item" }, optionStringify.substring(0, index)),
                React.createElement("span", { className: "list-item highlighter" }, optionStringify.substring(index, index + valueStringify.length)),
                React.createElement("span", { className: "list-item" }, optionStringify.substring(index + valueStringify.length))));
        return option;
    }
    ;
    var filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: function (option) {
            if (_.isArray(searchKey)) {
                return searchKey.reduce(function (acc, key) {
                    return (acc = acc + _.get(option, key));
                }, "");
            }
            else {
                return _.get(option, searchKey);
            }
        },
    });
    // on reset event fire
    var clearInput = function () {
        setInputValue("");
        setValue(null);
    };
    //style of rendering the drop down box values
    function styledRenderOption(props, option, _a) {
        var inputValue = _a.inputValue;
        return (React.createElement(React.Fragment, null, props["data-option-index"] === 0 ? (React.createElement(React.Fragment, null,
            React.createElement("li", __assign({}, props, { key: _.uniqueId("option_header_"), id: _.uniqueId("option_header_"), "data-option-index": -1, className: "disabled" }),
                React.createElement("span", { className: "list-header" }, col1Header),
                React.createElement("span", { className: "list-header" }, col2Header)),
            React.createElement("li", __assign({}, props, { key: _.uniqueId("option_"), id: _.uniqueId("option_") }),
                highlightMatchingText(_.get(option, col1Key), inputValue),
                React.createElement("div", { className: "col2-item" },
                    React.createElement("span", { className: "list-item" }, _.get(option, col2Key)),
                    React.createElement("span", { className: "list-item-sub" }, _.get(option, col2SubHeadingKey)))))) : (React.createElement("li", __assign({}, props, { key: _.uniqueId("option_"), id: _.uniqueId("option_") }),
            highlightMatchingText(_.get(option, col1Key), inputValue),
            React.createElement("div", { className: "col2-item" },
                React.createElement("span", { className: "list-item" }, _.get(option, col2Key)),
                React.createElement("span", { className: "list-item-sub" }, _.get(option, col2SubHeadingKey)))))));
    }
    ;
    return (React.createElement("div", { className: "autocomplete-grid-wrapper" },
        React.createElement(Autocomplete, { id: props.id || "autocomplete-grid", className: "autocomplete-grid", sx: { width: ((_k = props.schema) === null || _k === void 0 ? void 0 : _k.width) ? (_l = props.schema) === null || _l === void 0 ? void 0 : _l.width : "100%" }, disablePortal: true, value: value || null, filterOptions: filterOptions, inputValue: inputValue || "", disabled: props.disabled, readOnly: props.readonly, getOptionLabel: function (option) {
                return typeof option === "string"
                    ? option
                    : _.get(option, inputLabelKey);
            }, onInputChange: function (e, value) {
                setInputValue(value);
                props.onChange(value);
            }, onChange: function (e, value) {
                setValue(value);
            }, onBlur: function (event) { return props.onBlur(props.id, value); }, onFocus: function (event) { return props.onFocus(props.id, value); }, options: options, loading: loading, renderOption: styledRenderOption, onOpen: function (e) { return setOpen(true); }, onClose: function (e) { return setOpen(false); }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { required: props.required, disabled: props.disabled, placeholder: "Search...", size: "small", InputProps: __assign({}, params.InputProps) }))); } })));
}
