// Public.

export const defaultInitialSortValue = 'dueDate,ASC';

export const defaultSortOptions = [
  {
    name: 'Task | ASC',
    value: 'taskTitle,ASC',
  },
  {
    name: 'Task | DESC',
    value: 'taskTitle,DESC',
  },
  {
    name: 'Due | ASC',
    value: 'dueDate,ASC',
  },
  {
    name: 'Due | DESC',
    value: 'dueDate,DESC',
  },
  {
    name: 'Creation | ASC',
    value: 'createdDateTime,ASC',
  },
  {
    name: 'Creation | DESC',
    value: 'createdDateTime,DESC',
  },
];

export const modals = {
  COMPLETE: 'complete',
  FORM: 'form',
};

export const completeTaskModal = {
  heading: 'Complete Task',
  description:
    "The task would disappear from the Card and would be marked as 'Completed'.",
  actionLabel: 'Complete',
};

export const tasksFormModal = {
  addHeading: 'Add Task',
  editHeading: 'Update Task',
  oldDateError: "Date should be either today or in future",
  invalidEmailError: 'One or more email entry is invalid',
  missingReminderDateError: 'Reminder Date is required to send reminder',
  sendReminderLimitError: 'Should not be longer than 1000 characters',
  taskTitleLimitError: 'Should not be longer than 255 characters'
};

export const itemLevelCompleteError = 'This task was not completed.';
