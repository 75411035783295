// Libraries.

import React, { useState } from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';

// Dependencies.

import { DatePicker } from '@optum-osgp-temp/osgp-ui-component-lib';

// Private.

let uniqueIdPostFix = 0;

const PopperProps: any = {
  placement: 'bottom-start',
};

// Public

export const GridFilterDatePicker = ({
  item,
  applyValue,
  apiRef,
  focusElementRef,
}: GridFilterInputValueProps) => {
  const [id] = useState(
    () => `osgp-data-grid-filter-date-picker-${uniqueIdPostFix++}`
  );

  const handleChange = (newValue: any) => {
    const isDate = newValue instanceof Date && !isNaN(newValue.getDate());
    applyValue({ ...item, value: isDate ? newValue.toISOString() : undefined });
  };

  return (
    <span className="osgp-data-grid-filter-date-picker-container">
      <label htmlFor={id} className="MuiInputLabel-root">
        {apiRef.current.getLocaleText('filterPanelInputLabel')}
      </label>
      <DatePicker
        inputRef={focusElementRef}
        value={item.value || null}
        onChange={handleChange}
        id={id}
        required={false}
        placeholder="MM/DD/YYYY"
        PopperProps={PopperProps}
      />
    </span>
  );
};
