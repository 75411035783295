// Public.

export const menuOptions = ['Add Note', 'View All'];

export const defaultInitialSortValue = 'createdDateTime,DESC';

export const defaultSortOptions = [
  {
    name: 'Title | ASC',
    value: 'noteTitle,ASC',
  },
  {
    name: 'Title | DESC',
    value: 'noteTitle,DESC',
  },
  {
    name: 'Creation | ASC',
    value: 'createdDateTime,ASC',
  },
  {
    name: 'Creation | DESC',
    value: 'createdDateTime,DESC',
  },
];

export const closeNoteModal = {
  heading: "Close Note",
  description: "Are you sure you want to close this Note? This action cannot be undone.",
  actionLabel: "Close"
}

export const modals = {
  DELETE: 'delete',
  FORM: 'form',
};

export const addNoteModal = {
  heading: "Add Note",
  editHeading: "Update Note",
  noteTitleLimitError: "Should not be longer than 255 characters"
}

export const itemLevelDeleteError = 'This note was not closed.';
