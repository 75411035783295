import React from 'react';
import { GlobalNavExample } from "../components/NavigationBar";

function Train(){
    return(
      <div style={{marginBottom:'5px'}}>
      <GlobalNavExample/>
      <div>Train Page is in developing</div>
      </div>
    );
  }
  export default Train