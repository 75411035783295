import React from 'react';
import "./OSGPLoader.scss";
import { LoadingIndicator } from "@optum-osgp-temp/osgp-ui-component-lib";

interface OSGPLoaderI {
  loading: boolean
}

export const OSGPLoader = ({ loading = false }: OSGPLoaderI) => {
  return loading ?
    <div className="overlay">
      <LoadingIndicator size={"l"} loading={true} loadingText="Loading, please wait..." accessibilityText={"loading"} className='modal-loader-comp' />
    </div>
    : null;
}