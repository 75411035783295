// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pdf {
  min-width: 40%;
  flex:1 1;
}

.model{
  min-width: 60%;
  flex:1 1;
}


@media only screen and (max-width: 768px) {
  .pdf,
  .model {
    flex-basis: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,QAAM;AACR;;AAEA;EACE,cAAc;EACd,QAAM;AACR;;;AAGA;EACE;;IAEE,gBAAgB;EAClB;AACF","sourcesContent":[".page {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.pdf {\n  min-width: 40%;\n  flex:1;\n}\n\n.model{\n  min-width: 60%;\n  flex:1;\n}\n\n\n@media only screen and (max-width: 768px) {\n  .pdf,\n  .model {\n    flex-basis: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
