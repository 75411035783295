var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Dropdown } from '@optum-osgp-temp/osgp-ui-component-lib';
import React from 'react';
import * as _ from 'lodash';
function getDropdownItems(enumOptions) {
    var options = enumOptions.map(function (_a, i) {
        var value = _a.value, label = _a.label;
        return ({
            id: i + 1,
            label: label,
            value: value,
        });
    });
    return options;
}
function getSelectedItem(dropDownOptions, val) {
    var selectedItem = dropDownOptions.find(function (o) { return o.value === val; });
    return selectedItem;
}
function getMultipleSelectedItems(dropDownOptions, val) {
    var selectedItems = dropDownOptions.filter(function (o) {
        return val.includes(o.value);
    });
    return selectedItems;
}
export default function OSGPDropdown(props) {
    var _a, _b, _c;
    console.log('OSGPDropdown rendered uischema', props);
    var DropdownOptions = getDropdownItems(_.cloneDeep(props.options.enumOptions));
    if (!props.multiple && ((_a = props.schema) === null || _a === void 0 ? void 0 : _a.default) === undefined) {
        DropdownOptions.splice(0, 0, {
            id: 0,
            label: props.placeholder || 'Please Select',
            value: undefined,
        });
    }
    var ariaLabels = {};
    console.log("props.schema.description", props.schema.description);
    console.log("props.schema.title", props.schema.title);
    if (props.schema.description)
        ariaLabels['aria-describedby'] = props.id + '-ht';
    if (props.schema.title)
        ariaLabels['aria-labelledby'] = props.id + '-lbl';
    console.log('ariaLabels in dropdown', ariaLabels);
    function errorMultiLevel() {
        return props.rawErrors === undefined ||
            (props.rawErrors !== undefined && props.rawErrors.length === 0)
            ? ''
            : props.rawErrors.toString();
    }
    function multiFieldsetLabel() {
        var _a;
        return props.options.hasOwnProperty('fieldsetLabel') &&
            typeof ((_a = props.options) === null || _a === void 0 ? void 0 : _a.fieldsetLabel) === 'string'
            ? props.options.fieldsetLabel
            : '';
    }
    function multiPlaceholderText() {
        var _a;
        return props.options.hasOwnProperty('placeholder') &&
            typeof ((_a = props.options) === null || _a === void 0 ? void 0 : _a.placeholder) === 'string'
            ? props.options.placeholder
            : undefined;
    }
    function onSingleError() {
        return props.rawErrors === undefined ||
            (props.rawErrors !== undefined && props.rawErrors.length === 0)
            ? ''
            : props.rawErrors.toString();
    }
    // Single and Multi Dropdown
    return (React.createElement(React.Fragment, null, props.multiple === false ? (React.createElement(Dropdown, __assign({ id: props.id, type: "single", value: getSelectedItem(DropdownOptions, props.value), required: props.required, disabled: props.disabled || props.readonly, placeholderText: props.placeholder, items: DropdownOptions, error: onSingleError(), onChange: function (event) { return props.onChange(event.value); }, onBlur: function () { return props.onBlur(props.id, props.value); }, labelledBy: props.schema.title === undefined ? '' : props.id + '-lbl' }, (_b = props === null || props === void 0 ? void 0 : props.uiSchema) === null || _b === void 0 ? void 0 : _b.additionalProps))) : (React.createElement(Dropdown, __assign({ id: props.id, type: "multi", value: getMultipleSelectedItems(DropdownOptions, props.value), required: props.required, disabled: props.disabled || props.readonly, placeholderText: multiPlaceholderText(), items: DropdownOptions, fieldsetLabel: multiFieldsetLabel(), error: errorMultiLevel(), onChange: function (event) {
            console.log('event: ', event);
            console.log('event map: ', event.map(function (obj) { return obj.value; }));
            var selectedOptions = event.map(function (obj) { return obj.value; });
            props.onChange(selectedOptions.length !== 0 ? selectedOptions : undefined);
        }, onBlur: function () { return props.onBlur(props.id, props.value); }, labelledBy: props.schema.title === undefined ? '' : props.id + '-lbl', "aria-describedby": props.schema.description === undefined ? '' : props.id + '-ht' }, (_c = props === null || props === void 0 ? void 0 : props.uiSchema) === null || _c === void 0 ? void 0 : _c.additionalProps)))));
}
OSGPDropdown.defaultProps = {
    multiple: false,
    required: false,
    disabled: false,
    readonly: false,
};
