import React from "react";
import {Outlet} from "react-router-dom";

const Layout = () => {
  return (
    <div style={{marginBottom:'5px'}}>
        <Outlet />
    </div>
  );
}
export default Layout