var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox } from "@optum-osgp-temp/osgp-ui-component-lib";
import React from 'react';
export default function OSGPCheckbox(props) {
    console.log("OSGPCheckbox new rendered", props);
    var label = props.label, id = props.id, value = props.value, disabled = props.disabled, required = props.required, readonly = props.readonly, autofocus = props.autofocus, onBlur = props.onBlur, onFocus = props.onFocus, onChange = props.onChange, rawErrors = props.rawErrors, additionalProps = props.options.additionalProps, otherProps = __rest(props, ["label", "id", "value", "disabled", "required", "readonly", "autofocus", "onBlur", "onFocus", "onChange", "rawErrors", "options"]);
    var ariaLabels = {};
    if (props.schema.description)
        ariaLabels["aria-describedby"] = props.id + '-ht';
    // if (props.schema.title) ariaLabels["aria-labelledby"] = props.id + '-lbl';
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, __assign({ id: id, value: value, required: required, disabled: disabled, checked: typeof value === "undefined" ? false : value, readOnly: readonly, autoFocus: autofocus, onBlur: function (event) { onBlur(id, event.target.checked); }, onFocus: function (event) { onFocus(id, event.target.checked); }, error: typeof rawErrors === "undefined" ? false : (rawErrors.toString().trim().length > 0 ? true : false), onChange: function (event) { onChange(event.target.checked ? event.target.checked : false); } }, additionalProps, ariaLabels), label)));
}
OSGPCheckbox.defaultProps = {
    autofocus: false
};
