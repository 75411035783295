import { Link } from "@optum-osgp-temp/osgp-ui-component-lib";
import React, { useLayoutEffect, useState } from 'react';


export const OSGPNewsAndAnnouncementsListItem = (props: any) => {

  const [listItemData, setListItemData]: any = useState<any>(undefined);

  const newsDateFormatOptions: any = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const dateFormatOptions: any = { year: 'numeric', month: 'short', day: 'numeric' };

  const getFormattedDate = (dateStr: string, locale: string = 'EN', type: any = 'News') => {
    if (dateStr !== undefined && dateStr.trim().length > 0) {
      try {
        const currentDate = new Date(dateStr);
        if (type === 'News') {
          return currentDate.toLocaleDateString(locale, newsDateFormatOptions);
        } else {
          return currentDate.toLocaleDateString(locale, dateFormatOptions);
        }
      } catch (error: any) {
      }
    }

    return "";
  }
  const getLinkUrl = (baseURL: string, link: string) => {
    if(link !== undefined) {
      if(link.startsWith('http')) {
        return link;
      } else {
        return baseURL + link;
      }
    }
  }

  useLayoutEffect(() => {

    if (props?.data?.contentDetailByLang[props?.locale]) {
      setListItemData(props?.data?.contentDetailByLang[props?.locale]);
    } else if (props?.data?.contentDetailByLang['EN']) {
      setListItemData(props?.data?.contentDetailByLang['EN']);
    } else {
      setListItemData(undefined);
    }
  }, [props?.data, props?.locale]);

  return (
    <>
      {listItemData &&
        <div className={"news-item " + (props?.index === 1 ? 'even-row' : 'odd-row')}>
          <div className="row"><Link href={getLinkUrl(props?.baseURL, listItemData.link)} target="_blank">
            {listItemData.title}
          </Link></div>
          <div className="row"><span className='date-time'>{getFormattedDate(props?.data?.effectiveStartDate, props?.locale, props?.type)}</span></div>
          <div className="row"><p className='news-description'>{listItemData.description}</p></div>
        </div>
      }
    </>
  );
};
