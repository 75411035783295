// Libraries.

import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridFilterItem,
  GridCellParams,
} from '@mui/x-data-grid';

// Dependencies.

import { GridFilterDatePicker } from './GridFilterDatePicker';

// Private.

const defaultGridNumericOperators = getGridNumericOperators();

const numericEqualToOperator = defaultGridNumericOperators.find(
  ({ value }) => value === '='
);

const numericContainsOperator = {
  ...numericEqualToOperator,
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (
      filterItem.value === undefined ||
      filterItem.value === null ||
      isNaN(filterItem.value)
    ) {
      return null;
    }

    const lowerCaseFilterItemValue = String(filterItem.value).toLowerCase();

    return (params: GridCellParams): boolean =>
      String(params.value).toLowerCase().includes(lowerCaseFilterItemValue);
  },
};

// Public.

export const gridDateFilterOperators = getGridDateOperators().map(
  (operator) => ({
    ...operator,
    InputComponent: operator.InputComponent ? GridFilterDatePicker : undefined,
  })
);

export const gridDateTimeFilterOperators = getGridDateOperators(true);

export const gridNumericFilterOperators = [
  numericContainsOperator,
  ...defaultGridNumericOperators,
];

export const gridBooleanOperators = getGridBooleanOperators();

export const gridStringOperators = getGridStringOperators();

export const gridSingleSelectOperators = getGridSingleSelectOperators();
