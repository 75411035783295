var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RadioButton } from "@optum-osgp-temp/osgp-ui-component-lib";
import React from "react";
export default function OSGPRadioButton(props) {
    console.log("OSGPRadioButton rendered", props);
    var options = props.options, value = props.value, disabled = props.disabled, readonly = props.readonly, id = props.id, rawErrors = props.rawErrors, required = props.required, schema = props.schema, onChange = props.onChange, onBlur = props.onBlur, onFocus = props.onFocus, autofocus = props.autofocus, otherProps = __rest(props, ["options", "value", "disabled", "readonly", "id", "rawErrors", "required", "schema", "onChange", "onBlur", "onFocus", "autofocus"]);
    var enumOptions = options.enumOptions, enumDisabled = options.enumDisabled, inline = options.inline, name = options.name;
    var styles = inline
        ? { display: "inline-flex", width: "100%", flexWrap: "wrap" }
        : { display: "inherit" };
    // console.log("The additional props of radio button are: ",otherProps.uiSchema.additionalProps)
    // console.log("Radio button - props.name :", name)
    var descriptionId = schema.description === undefined ? "" : "".concat(id, "-ht ");
    var labelId = schema.title === undefined ? "" : "".concat(id, "-lbl");
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", { style: styles }, enumOptions && enumOptions.map(function (option, index) {
                var _a;
                // Generating a unique field name to identify this set of radio buttons
                var names = Math.random().toString();
                // const label = option.label;
                var checked = option.value === value;
                var itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) != -1;
                //const disabledCls = disabled || itemDisabled || readonly ? "disabled" : "";
                return (React.createElement(RadioButton, __assign({ checked: checked, id: "".concat(id, "_").concat(names), value: option.value, name: name, error: typeof rawErrors === "undefined" ? false : (rawErrors.toString().trim().length > 0 ? true : false), required: required, disabled: disabled || itemDisabled || readonly, autoFocus: autofocus && index === 0, onChange: function (_) { return onChange(option.value); }, onBlur: onBlur && (function (event) { return onBlur(id, event.target.value); }), onFocus: onFocus && (function (event) { return onFocus(id, event.target.value); }) }, (_a = otherProps === null || otherProps === void 0 ? void 0 : otherProps.uiSchema) === null || _a === void 0 ? void 0 : _a.additionalProps, { "aria-labelledby": "".concat(id, "_").concat(index, "-lbl"), "aria-describedby": labelId + " " + descriptionId }),
                    React.createElement("span", { id: "".concat(id, "_").concat(index, "-lbl") }, option.label)));
            })))));
}
OSGPRadioButton.defaultProps = {
    required: false,
    disabled: false,
    readonly: false
};
