// Libraries.

import React, { useEffect } from "react";
import { ListItemButton } from "@optum-osgp-temp/osgp-ui-component-lib";

// Private.

import { getFormattedDate } from "../../utilities/dates";
import { getFullClassName } from "../../utilities/getFullClassName";
import { OSGPNotesTasksItemLevelError } from "../OSGPNotesTasksItemLevelError";
import { itemLevelDeleteError, modals } from "./constants";

// Public.

export const OSGPNoteListItem = ({
  errorListItemStateSetter,
  listItemData,
  listItemState,
  setListItemState,
  setModalState,
}: any) => {
  const { createdDateTime, noteDescription, noteTitle } = listItemData;
  const { isDeleteError } = listItemState;
  const containerClassName = getFullClassName([
    "osgp-note-listitem-container",
    isDeleteError && "osgp-note-listitem-container-with-error",
  ]);

  const handleClick = () => {
    setModalState({
      openModal: modals.FORM,
      listItemData,
      setListItemState,
    });
  };

  const handleErrorDismiss = () => {
    setListItemState((prev: any) => ({ ...prev, isDeleteError: false }));
  };

  useEffect(() => {
    // If there is no error in the list, ensure the parent does not track it.
    if (!isDeleteError) {
      errorListItemStateSetter.current.delete(listItemData.noteId);
    } else {
      // Else, hoist the state setter for the parent.
      errorListItemStateSetter.current.set(
        listItemData.noteId,
        setListItemState
      );
    }

    return () => {
      // Clear the hoisted state setter.
      errorListItemStateSetter.current.delete(listItemData.noteId);
    };
  }, [isDeleteError, errorListItemStateSetter, listItemData, setListItemState]);

  return (
    <div className={containerClassName}>
      <ListItemButton className="osgp-note-listitem" onClick={handleClick}>
        <div title={noteTitle} className="osgp-note-title">
          {noteTitle}
        </div>
        <div title={noteDescription} className="osgp-note-description">
          {noteDescription}
        </div>
        <div className="osgp-note-date">
          Created on {getFormattedDate(createdDateTime)}
        </div>
      </ListItemButton>
      {isDeleteError ? (
        <OSGPNotesTasksItemLevelError
          message={itemLevelDeleteError}
          onDismiss={handleErrorDismiss}
        />
      ) : null}
    </div>
  );
};
