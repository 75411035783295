// Libraries.

import React, { useMemo, useState } from 'react';
import {
  gridFilterActiveItemsLookupSelector,
  useGridSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { Button, Icon } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { GridFilterPanel } from './GridFilterPanel';
import { GridToolbarFilterProps } from './DataGridProps';
import { GridBasicFilterPanel } from './GridBasicFilterPanel';

// Private.

const filterIcon = <Icon iconName="OSGPFilter" />;

// Public.

export const GridToolbarFilter = ({
  disableAdvanceFilters,
  customBasicFilterLabels,
}: GridToolbarFilterProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const apiRef = useGridApiContext();
  const filterActiveItems = useGridSelector(
    apiRef,
    gridFilterActiveItemsLookupSelector
  );

  const handleFilterToggle = () => {
    setIsFilterOpen((previous) => !previous);
  };

  const numberOfActiveFilters = useMemo(() => {
    let count = 0;
    Object.values(filterActiveItems).forEach((array) => {
      count = count + array.length;
    });
    return count ? ` (${count})` : null;
  }, [filterActiveItems]);

  return (
    <div className="osgp-data-grid-toolbar-filter-container">
      <Button
        variant="alternative"
        onPress={handleFilterToggle}
        className="osgp-data-grid-filter-button"
        icon={filterIcon}
        aria-label={ numberOfActiveFilters ? `${numberOfActiveFilters} Filters applied to records table` : "No Filters applied to records table"}
      >
        {isFilterOpen ? 'Hide' : 'Show'} Filters{numberOfActiveFilters}
      </Button>
      {isFilterOpen && (
        <section className="osgp-data-grid-filter-panel-container">
          <h3>Filters</h3>
          {disableAdvanceFilters ? (
            <GridBasicFilterPanel
              customBasicFilterLabels={customBasicFilterLabels}
            />
          ) : (
            <GridFilterPanel />
          )}
        </section>
      )}
    </div>
  );
};
