import { TextInput } from "@optum-osgp-temp/osgp-ui-component-lib"
import React, { useState } from "react"

export const OtherComponent = (props: any) => {
    const [otherVal, setOtherVal] = useState("")

    return (

        <div className="other-component-selecttag-dropdown">
            <div style={{ padding: 5 }}></div>
            <TextInput
                onChange={(value) => {
                    value.preventDefault()
                    setOtherVal(value.target.value)
                    props.onAdd(value.target.value);
                }}
                style={{ ...props.css }}
                value={otherVal}
            />
        </div>


    )
}

