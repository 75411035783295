var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TextInput } from "@optum-osgp-temp/osgp-ui-component-lib";
import React from 'react';
export default function OSGPTextInput(props) {
    var _a;
    var uiOptions = (_a = props.uiSchema) === null || _a === void 0 ? void 0 : _a["ui:options"];
    var ariaLabels = {};
    if (props.schema.description)
        ariaLabels["aria-describedby"] = props.id + '-ht';
    if (props.schema.title)
        ariaLabels["aria-labelledby"] = props.id + '-lbl';
    return React.createElement(React.Fragment, null,
        React.createElement(TextInput, __assign({ id: props.id, value: props.value || "", required: props.required, disabled: props.disabled, readOnly: props.readonly, autoFocus: props.autofocus, placeholder: props.placeholder, onBlur: function (event) { props.onBlur(props.id, event.target.value); }, onFocus: function (event) { props.onFocus(props.id, event.target.value); }, onChange: function (event) { props.onChange(event.target.value ? event.target.value : undefined); }, error: typeof props.rawErrors === "undefined" ? "" : props.rawErrors.toString() }, ariaLabels, uiOptions)));
}
