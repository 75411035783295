// Libraries.

import React from 'react';
import {
  GridFilterPanel as DefaultGridFilterPanel,
  useGridRootProps,
} from '@mui/x-data-grid-premium';

// Private.

const defaultFilterFormProps = {
  columnInputProps: {
    variant: 'outlined',
  },
  operatorInputProps: {
    variant: 'outlined',
  },
  logicOperatorInputProps: {
    variant: 'outlined',
  },
  valueInputProps: {
    InputComponentProps: {
      variant: 'outlined',
    },
  },
};

// Public.

export const GridFilterPanel = () => {
  const { slotProps } = useGridRootProps();
  const { filterFormProps, ...otherFilterPanelSlotProps } =
    slotProps?.filterPanel || {};

  return (
    <div className="osgp-data-grid-filter-panel">
      <DefaultGridFilterPanel
        filterFormProps={{
          ...defaultFilterFormProps,
          ...filterFormProps,
        }}
        {...otherFilterPanelSlotProps}
      />
    </div>
  );
};
