import React from 'react';
import { Icon, Notification } from '@optum-osgp-temp/osgp-ui-component-lib';
import './OSGPNotificationBanner.scss';

interface OSGPNotificationBannerI {
  toastMsg: string;
  setToastMsg: Function;
  type?: 'error' | 'success';
}

const icon = (
  <>
    <span className='a11y-only-content'>Dismiss Notification</span>
    <Icon iconName="Close" className="notification-close-button" />
  </>
);

export const OSGPNotificationBanner = ({
  toastMsg,
  setToastMsg,
  type,
}: OSGPNotificationBannerI) => {
  const handleDismiss = () => setToastMsg('');
  
  return (
    <Notification
      variant={type}
      className="osgp-notification-banner"
      buttonChildren={icon}
      onDismiss={handleDismiss}
      dismissable
    >
      {toastMsg}
    </Notification>
  );
};
