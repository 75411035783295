// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datagrid{
    margin-top: 62px;
    height: 480px;
    overflow: auto;
}

.cellTextOverflow {  
    white-space: nowrap;  
    overflow: hidden;  
    text-overflow: ellipsis;  
}  `, "",{"version":3,"sources":["webpack://./src/components/CollapsibleTable.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".datagrid{\n    margin-top: 62px;\n    height: 480px;\n    overflow: auto;\n}\n\n.cellTextOverflow {  \n    white-space: nowrap;  \n    overflow: hidden;  \n    text-overflow: ellipsis;  \n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
