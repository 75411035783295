import React, { useEffect, useRef, useState } from "react";
import {
  Tag,
  Dropdown,
  DropdownItem,
  DropdownProps,
  TextInput,
} from "@optum-osgp-temp/osgp-ui-component-lib";
import { OSGPSelectWithTagProps } from "./OSGPSelectProps";
import { apiCall } from "../../utilities/serviceUtils";
import "./style.scss";
import { OtherComponent } from "./OSGPOtherComponent";
import * as _ from "lodash"

export const OSGPSelectWithTag = (props: OSGPSelectWithTagProps) => {
  const {
    title,
    isMultiSelect = false,
    required,
    apiUrl,
    options = [],
    selectedValues,
    otherComponentValue
  } = props;

  const [dropdownOptions, setDropdownOptions] =
    useState<DropdownItem[]>(options);
  const [selectedOptions, setSelectedOptions] = useState<DropdownItem[]>([]);
  const [otherOptions, setOtherOptions] = useState<DropdownItem[]>([]);
  const [error, setError] = useState<string>("");
  const [otherComponent, setOtherComponent] = useState<string | React.ReactElement | boolean | null>(null)
  useEffect(() => {
    if (apiUrl?.includes("http")) {
      apiCall({
        baseUrl: apiUrl,
        route: "",
        method: "get",
        headers: {},
      }).then(
        ({ json, status = null, isError = false, errorCaught = null }: any) => {
          if (status !== 200 || (isError && errorCaught)) {
            setError("Error during fetching the options");
            setDropdownOptions([]);
          } else {
            setDropdownOptions(json || []);
          }
        }
      );
    }
  }, [apiUrl]);

  const onDelete = (option: DropdownItem) => {
    const selectedOptionsCopy = [...selectedOptions];
    selectedOptionsCopy.splice(
      selectedOptions.findIndex(
        (loopOption) => option.value === loopOption.value
      ),
      1
    );
    setSelectedOptions(selectedOptionsCopy);
    const temp = removeOther(selectedOptionsCopy)
    selectedValues([...temp, ...otherOptions]);

    const findOther = selectedOptionsCopy.filter(filterOthers)
    otherSelect(findOther as any);
  };


  const onChange = (option: any) => {
    let updateSelectedOptions = isMultiSelect ? option : [option];
    const findOther = updateSelectedOptions.filter(filterOthers)
    otherSelect(findOther);
    setSelectedOptions(updateSelectedOptions);
    selectedValues([...updateSelectedOptions]);
  };

  const otherSelect = (findOther: DropdownProps[]) => {

    if (findOther && findOther.length) {
      if (other[0].label.toLowerCase() == "other" || other[0].label.toLowerCase() == "others") {
        setOther();

        if (React.isValidElement(props.otherComponent)) {
          setOtherComponent(props.otherComponent)
        }
      } else {
        setOtherComponent(
          null
        )
      }
    } else {
      setOtherComponent(
        null
      )
    }
  }

  function setOther() {
    if (props.otherComponent == "TextInput") {
      setOtherComponent(
        true
      );
    }
  }

  const removeOther = (options: any) => {
    return options.filter((val: any) => val.label.toLocaleLowerCase() != "other" && val.label.toLocaleLowerCase() != "others")
  }

  const filterOthers = (val: DropdownItem) => {
    return val.label.toLocaleLowerCase() == "other" || val.label.toLocaleLowerCase() == "others"
  }

  const baseDropdownProps: any = {
    items: dropdownOptions,
    onChange: onChange,
    className: "dropdown-select",
  };

  const singleDropDownProps: DropdownProps = {
    ...baseDropdownProps,
    type: "single",
    value: selectedOptions[0] || [],
  };
  const multiDropDownProps: DropdownProps = {
    ...baseDropdownProps,
    type: "multi",
    value: selectedOptions,
    fieldsetLabel: "",
  };

  const other = singleDropDownProps.items.filter(filterOthers)

  const onChangeOther = (value: string) => {
    if (otherComponentValue !== undefined) otherComponentValue(value!)
  }

  return (
    <div className="osgp-select-with-tag" data-testid="osgp-select-with-tag">

      <h4 className="margin-bottom-12">
        {title}
        {required && <span className="select-required">*</span>}
      </h4>
      {selectedOptions.length
        ? selectedOptions.map((option) => {
          return (
            <Tag
              key={option.label}
              leftAlignDeleteIcon
              label={option?.label}
              onDelete={() => onDelete(option)}
              className="margin-bottom-12"
            />
          )
        })
        : null}
      {error && <div className="error-text">{error}</div>}
      <div className={props.wrapperClass || ""}>
        <Dropdown
          {...(isMultiSelect ? multiDropDownProps : singleDropDownProps)}
        />

        {otherComponent == true ? <OtherComponent css={props.otherComponentStyle} onAdd={onChangeOther} /> : otherComponent}
      </div>
    </div >
  );
};
