// Libraries.

import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  useGridSelector,
  useGridApiContext,
  gridFilteredTopLevelRowCountSelector,
  useGridRootProps,
} from '@mui/x-data-grid-premium';
import {
  Button,
  BaseMenu as Menu,
  BaseMenuItem as MenuItem,
  Icon,
} from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { GridExportsProps } from './DataGridProps';

// Private.

const GridExportsContext = createContext<GridExportsProps>({});

// Public.

export const GridExportsContextProvider = GridExportsContext.Provider;

export const GridExports = () => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const {
    csvExportOptions,
    disableExcelExport,
    disableCsvExport,
    disablePrintExport,
    excelExportOptions,
    hideToolbarRowCount,
    printExportOptions,
    excelExportLabel,
    csvExportLabel,
    printExportLabel,
    exportMenuLabel,
    onExport,
    exportMode,
  } = useContext(GridExportsContext);
  const topLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  );
  const [open, setOpen] = useState(false);
  const exportButtonRef: any = useRef(null);
  const numberOfDisabledExports = [
    disableExcelExport,
    disableCsvExport,
    disablePrintExport,
  ].filter(Boolean).length;
  const rowCount = rootProps.rowCount ?? topLevelRowCount ?? 0;
  const isExportVisible = rowCount !== 0 && numberOfDisabledExports !== 3;
  const isExportMenu = isExportVisible && numberOfDisabledExports < 2;
  const runDefaultHandler = exportMode !== 'server';

  const handleMenuClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExcelExport = useCallback(() => {
    handleMenuClose();
    onExport?.('excel');

    if (runDefaultHandler) {
      apiRef.current.exportDataAsExcel(excelExportOptions);
    }
  }, [
    apiRef,
    excelExportOptions,
    handleMenuClose,
    onExport,
    runDefaultHandler,
  ]);

  const handleCSVExport = useCallback(() => {
    handleMenuClose();
    onExport?.('csv');

    if (runDefaultHandler) {
      apiRef.current.exportDataAsCsv(csvExportOptions);
    }
  }, [apiRef, csvExportOptions, handleMenuClose, onExport, runDefaultHandler]);

  const handlePrintExport = useCallback(() => {
    handleMenuClose();
    onExport?.('print');

    if (runDefaultHandler) {
      apiRef.current.exportDataAsPrint(printExportOptions);
    }
  }, [
    apiRef,
    handleMenuClose,
    onExport,
    printExportOptions,
    runDefaultHandler,
  ]);

  const handleMenuClick = useCallback(() => {
    if (isExportMenu) {
      setOpen((previous) => !previous);
      return;
    }

    if (!disableExcelExport) {
      handleExcelExport();
    } else if (!disableCsvExport) {
      handleCSVExport();
    } else {
      handlePrintExport();
    }
  }, [
    disableCsvExport,
    disableExcelExport,
    handleCSVExport,
    handleExcelExport,
    handlePrintExport,
    isExportMenu,
  ]);

  const buttonText = isExportMenu
    ? exportMenuLabel
    : disableCsvExport
    ? disablePrintExport
      ? excelExportLabel
      : printExportLabel
    : csvExportLabel;

  return (
    <div className="osgp-data-grid-toolbar-export-container">
      {!hideToolbarRowCount ? `${rowCount} Records` : null}
      {isExportVisible && (
        <>
          {!hideToolbarRowCount ? ': ' : null}
          <Button
            className="osgp-data-grid-export-menu-button"
            buttonRef={exportButtonRef}
            variant="inverse"
            onPress={handleMenuClick}
            aria-label={`${rowCount} Records available to export to excel spreadsheet`}
          >
            {buttonText}
            {isExportMenu && (
              <Icon
                iconName={open ? 'ArrowUp' : 'ArrowDown'}
                stroke="#3271E1"
              />
            )}
          </Button>
          {isExportMenu && (
            <Menu
              disablePortal
              className="osgp-data-grid-export-menu"
              onClose={handleMenuClose}
              open={open}
              anchorEl={exportButtonRef.current}
            >
              {!disableExcelExport && (
                <MenuItem onClick={handleExcelExport}>
                  {excelExportLabel}
                </MenuItem>
              )}
              {!disableCsvExport && (
                <MenuItem onClick={handleCSVExport}>{csvExportLabel}</MenuItem>
              )}
              {!disablePrintExport && (
                <MenuItem onClick={handlePrintExport}>
                  {printExportLabel}
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}
    </div>
  );
};
