import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import CustomModelPage from "./pages/CustomModelPage";
import TestPage from "./pages/TestPage";
import Train from './pages/Train';
import {optum, OSGPToolkit} from "@optum-osgp-temp/osgp-ui-component-lib";
import "@optum-osgp-temp/osgp-ui-component-lib/themes/optum.css";
import  Login  from "./pages/Login";
import  ProtectedRoute from "./Utility/reUsable"
import React, { useState } from 'react';

export default function App() {
  const [isVerified, setIsVerified] = useState(false);
  return (
    <OSGPToolkit theme={optum} grid spacing>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login setIsVerified={setIsVerified} />} />
          <Route path="Home" element={
          <ProtectedRoute isVerified={isVerified}>
            <CustomModelPage />
          </ProtectedRoute>}/>
          <Route path="Test" element={
          <ProtectedRoute isVerified={isVerified}>
            <TestPage />
          </ProtectedRoute>}/>
          <Route path="Train" element={
          <ProtectedRoute isVerified={isVerified}>
            <Train />
          </ProtectedRoute>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </OSGPToolkit> 
  );
}
ReactDOM.render(<App />, document.getElementById("root"));