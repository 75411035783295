var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OSGPThemeProvider } from "../OSGPThemeProvider";
import React from 'react';
export function OSGPToolkit(props) {
    console.log('props : ', props);
    return React.createElement(React.Fragment, null,
        React.createElement(OSGPThemeProvider, __assign({}, props), props.children));
}
