// Libraries.

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Icon,
  PaginationTable,
} from '@optum-osgp-temp/osgp-ui-component-lib';
import {
  DynamicForm,
  DynamicFormV2Props,
} from '@optum-osgp-temp/ui-dynamic-forms-engine';
import { PressEvent } from '@react-types/shared';

// Dependencies.

import { compareDates } from '../../utilities/dates';
import { getFullClassName } from '../../utilities/getFullClassName';
import {
  FilterConfigType,
  OSGPPaginationTableWithFilterProps,
} from './OSGPPaginationTableWithFilterProps';
import './styles.scss';

// Private.

const filterIcon = <Icon iconName="Filter" />;

const MemoizedPaginationTable = memo(PaginationTable);

const formContext: DynamicFormV2Props['formContext'] = {
  showErrorIcon: true,
};

const applyDateFilter = ({ isToDate, filterValue, tableValue }: any) => {
  const filterDateWithTime = isToDate
    ? `${filterValue} 23:59:59`
    : `${filterValue} 00:00:00`;
  // Replace '/' with '-'.
  const formattedTableDate = tableValue.replace(/\//g, '-');
  const validDateComparisons = isToDate ? [0, 1] : [0, -1];
  const dateComparison = compareDates(filterDateWithTime, formattedTableDate);

  return validDateComparisons.includes(dateComparison);
};

// Public.

export const OSGPPaginationTableWithFilter = ({
  className,
  data,
  initialFiltersValue = {},
  filterConfig,
  tableConfig,
  exportButtonChildren = 'Export',
  onExport,
  paginationConfig: givenPaginationConfig,
  ...otherProps
}: OSGPPaginationTableWithFilterProps) => {
  // Build the full class name.
  const fullClassName = getFullClassName([
    'osgp-pagination-table-with-filter',
    className,
  ]);

  const [filters, setFilters] = useState<any>(initialFiltersValue);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tableData, setTableData] = useState(data);
  const filterCount = Object.keys(filters).length;

  const formRef = useRef<any>(null);
  const dynamicFormKey = useRef(0);

  const handleFilter = () => {
    const formData = formRef.current?.state?.formData || {};
    // Remove null and undefined values from form data.
    const filterData = Object.keys(formData).reduce(
      (intermediateObject, key) => {
        const value = formData[key];
        if (value === null || value === undefined) {
          return intermediateObject;
        }

        // If value is not null or undefined, add it the filter data.
        return {
          ...intermediateObject,
          [key]: value,
        };
      },
      {}
    );

    setFilters(filterData);
  };

  const handleClear = () => {
    // Change the dynamic form's key to render an empty form.
    // Needed because date picker does not clear once the value is entered.
    dynamicFormKey.current += 1;
    // Clear the filter values.
    setFilters({});
  };

  const handleFilterButtonPress = () => {
    // Toggle filter open state.
    setIsFilterOpen((prev) => !prev);
  };

  const handleExport = (event: PressEvent) =>
    onExport?.(event, { filterColumnMapping, filters, tableData });

  const paginationConfig = useMemo(
    () => ({
      ...givenPaginationConfig,
      // Add current items count to pagination config.
      totalItemsCount: tableData?.length || 1,
    }),
    [givenPaginationConfig, tableData]
  );

  const [schema, uiSchema, filterColumnMapping] = useMemo(() => {
    // If filter config does not exist, return don't do anything.
    if (!filterConfig) {
      return [undefined, undefined, undefined];
    }

    // Compute form schema and filter mappings.
    const properties = filterConfig.reduce(
      (formProperties: any, filter: FilterConfigType) => {
        const {
          columnKey,
          filterKey: givenFilterKey,
          type,
          schema,
          uiSchema,
          enum: enumValue,
          title,
        } = filter;
        const newFormProperties: any = { ...formProperties };
        const isToDate = type === 'to-date';
        const isFromDate = type === 'from-date';
        const isDate = isToDate || isFromDate;
        // Find column name associated with column key.
        const columnLabel = tableConfig?.columns?.find(
          ({ key }: any) => key === columnKey
        )?.label;
        // If title is not given for filter, use the column name.
        const filterTitle = title
          ? title
          : `${columnLabel}${isDate ? (isFromDate ? ' From' : ' To') : ''}`;
        // If filterKey is not given, use the columnKey.
        const filterKey = givenFilterKey
          ? givenFilterKey
          : isDate
          ? `${columnKey}_${isToDate ? '_ToDate' : '_FromDate'}`
          : columnKey;

        // Create entry in schema.
        newFormProperties.schema.properties[filterKey] = {
          type: isDate ? 'string' : type,
          enum: enumValue,
          title: filterTitle,
          ...(isDate && { format: 'date' }),
          ...schema,
        };

        // Create entry in uiSchema.
        newFormProperties.uiSchema[filterKey] = {
          gridPositionClass: 'col-m-4 col-l-3 filter-grid',
          ...(Boolean(enumValue) && { 'ui:placeholder': 'Select' }),
          ...(isDate && {
            PopperProps: {
              placement: 'bottom-start',
              disablePortal: true,
            },
            'ui:placeholder': 'MM/DD/YYYY',
          }),
          ...uiSchema,
        };

        // Create entry in filterColumnMapping.
        newFormProperties.filterColumnMapping[filterKey] = {
          columnKey,
          type,
          isToDate,
          isFromDate,
        };

        return newFormProperties;
      },
      {
        schema: { type: 'object', properties: {} },
        uiSchema: {},
        filterColumnMapping: {},
      }
    );

    return [
      properties.schema,
      properties.uiSchema,
      properties.filterColumnMapping,
    ];
  }, [filterConfig, tableConfig]);

  useEffect(() => {
    // If data or filters information is not available, return.
    if (!data || !filters || !filterColumnMapping) {
      return;
    }

    // Iterate over all the applied filters.
    const filteredTableData = Object.keys(filters).reduce(
      (intermediateData, filterKey) => {
        // Get filter and column data from mapping.
        const { columnKey, isToDate, isFromDate } =
          filterColumnMapping[filterKey];
        const filterValue = filters[filterKey].toLowerCase();

        // Filter the data.
        return intermediateData.filter((row) => {
          const tableValue = row[columnKey] ? row[columnKey].toLowerCase() : '';

          // If column is date, perform date filter.
          if (isToDate || isFromDate) {
            return applyDateFilter({ isToDate, filterValue, tableValue});
          }

          // Else, do a substring filter.
          return tableValue.includes(filterValue);
        });
      },
      data
    );

    // Update table data with new filtered data.
    setTableData(filteredTableData);
  }, [data, filters, filterColumnMapping]);

  return (
    <div className={fullClassName}>
      {filterConfig ? (
        <>
          <Button
            className="osgp-pagination-table-with-filter-toggle-filter-button"
            onPress={handleFilterButtonPress}
            variant="alternative"
            icon={filterIcon}
          >
            {isFilterOpen ? 'Hide' : 'Show'} Filters
            {filterCount ? ` (${filterCount})` : ''}
          </Button>
          {isFilterOpen && (
            <div className="osgp-pagination-table-with-filter-content">
              <h3>Filters</h3>
              <DynamicForm
                enableVersion2
                key={dynamicFormKey.current}
                ref={formRef}
                schema={schema}
                uiSchema={uiSchema}
                formContext={formContext}
                formData={filters}
                autoComplete="off"
                liveValidate={false}
              >
                <Button
                  type="submit"
                  onPress={handleFilter}
                  className="osgp-pagination-table-with-filter-apply-filter-button"
                >
                  Apply Filters
                </Button>
                {filterCount ? (
                  <Button
                    className="osgp-pagination-table-with-filter-clear-filter-button"
                    type="button"
                    variant="ghost-alternative"
                    onPress={handleClear}
                  >
                    Clear Filters
                  </Button>
                ) : null}
              </DynamicForm>
            </div>
          )}
        </>
      ) : null}

      <div className="osgp-pagination-table-with-filter-export-container">
        {tableData?.length || 0} Records
        {tableData?.length && onExport ? ': ' : ''}
        {tableData?.length && onExport ? (
          <Button size="s" variant="inverse" onPress={handleExport}>
            {exportButtonChildren}
          </Button>
        ) : null}
      </div>

      <MemoizedPaginationTable
        key={paginationConfig.totalItemsCount}
        data={tableData}
        paginationConfig={paginationConfig}
        tableConfig={tableConfig}
        {...otherProps}
      />
    </div>
  );
};
