// Libraries.

import React from "react";
import { useGridApiContext } from "@mui/x-data-grid";

// Dependencies.

import { TextInput } from "@optum-osgp-temp/osgp-ui-component-lib";

// Public

export const GridEditInputNumberCell = (props: any) => {
  const { id, field, value, colDef, inputProps } = props;
  const maxLength = inputProps?.maxLength;

  const apiRef = useGridApiContext();

  const handleNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    apiRef.current.setEditCellValue({ id, field, value: +event.target.value });
  };

  const OnInputForNumber = (event: any) => {
    event.target.value = Number(event.target.value).toFixed(0);
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  };

  const handleDecimalChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
  };

  const OnInputForDecimal = (event: any) => {
    event.target.value = event.target.value
      .replace(/[^\d\.]/g, "")
      .replace(/\./, "x")
      .replace(/\./g, "")
      .replace(/x/, ".");
  };

  return (
    <>
      {colDef?.type === "number" ? (
        <TextInput
          name={field}
          type={"text"}
          value={value}
          onChange={handleNumberChange}
          maxLength={maxLength}
          onInput={(event: any) => OnInputForNumber(event)}
        />
      ) : (
        <TextInput
          name={field}
          type={"text"}
          value={value}
          onChange={handleDecimalChange}
          maxLength={
            value?.toString().includes(".") ? maxLength + 1 : maxLength
          }
          onInput={(event: any) => OnInputForDecimal(event)}
        />
      )}
    </>
  );
};
