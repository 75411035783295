// Libraries.

import React from 'react';
import { Button, Icon } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { OSGPNotesTasksItemLevelErrorProps } from './OSGPNotesTasksItemLevelErrorProps';
import './styles.scss';

// Public.

export const OSGPNotesTasksItemLevelError = ({
  message,
  onDismiss,
}: OSGPNotesTasksItemLevelErrorProps) => (
  <div className="osgp-notes-tasks-item-level-error">
    <span className="osgp-notes-tasks-item-level-error-content">
      <Icon iconName="AlertFilled" fill="#C40000" />
      {message}
    </span>
    <Button variant="inverse" onPress={onDismiss}>
      <Icon
        iconName="Close"
        aria-label="Dismiss error"
        fill="#001747"
        stroke="#001747"
      />
      <span className="osgp-hidden-text">Dismiss error</span>
    </Button>
  </div>
);
