// Libraries.

import React, { useEffect } from 'react';
import { Button, Icon } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { getFullClassName } from '../../utilities/getFullClassName';
import { OSGPSortBy } from './OSGPSortBy';
import {
  SubheaderControlsProps,
  OSGPNotesTasksSubheaderProps,
} from './OSGPNotesTasksSubheaderProps';
import './styles.scss';

// Private.

const SubheaderControls = ({
  onAdd,
  onSort,
  sortOptions,
  sortValue,
}: SubheaderControlsProps) => (
  <div className="osgp-note-task-subheader-controls">
    <Button
      variant="ghost"
      size="s"
      className="osgp-note-task-add-button"
      onPress={onAdd}
    >
      <Icon
        ariaHidden
        iconName="Add"
        className="osgp-note-task-add-icon"
        stroke="#5A5A5A"
      />
      <span className="osgp-hidden-text">Add</span>
    </Button>
    <OSGPSortBy onChange={onSort} value={sortValue} options={sortOptions} />
  </div>
);

const SubheaderNotification = ({ notification, setNotification }: any) => {
  const { message, isError, isActive, key } = notification;
  const containerClassName = getFullClassName([
    'osgp-notes-tasks-subheader-notification',
    isActive && 'is-active',
  ]);
  const contentClassName = getFullClassName([
    'osgp-notes-tasks-subheader-notification-content',
    isError
      ? 'osgp-notes-tasks-subheader-notification-content-error'
      : 'osgp-notes-tasks-subheader-notification-content-success',
  ]);

  const handleTransitionEnd = () => {
    // If notification enter transition, don't do anything.
    if (isActive) {
      return;
    }

    // After the notification has transitioned out, rest the message.
    setNotification((prev: any) => ({
      ...prev,
      isError: false,
      message: null,
    }));
  };

  useEffect(() => {
    // If notification is not active, don't do anything.
    if (!isActive) {
      return;
    }

    // Set timer to hide the notification.
    const timeoutId = setTimeout(() => {
      setNotification((prev: any) => ({
        ...prev,
        isActive: false,
      }));
    }, 5000);

    return () => {
      // Clear timeout.
      clearTimeout(timeoutId);
    };
  }, [isActive, message, key, setNotification]);

  return (
    <div onTransitionEnd={handleTransitionEnd} className={containerClassName}>
      {message ? (
        <div className={contentClassName}>
          <Icon
            iconName={isError ? 'AlertFilled' : 'CheckmarkFilled'}
            fill={isError ? '#C40000' : '#FFFFFF'}
          />
          {message}
        </div>
      ) : null}
    </div>
  );
};

// Public.

export const OSGPNotesTasksSubheader = ({
  notification,
  onAdd,
  onSort,
  setNotification,
  sortOptions,
  sortValue,
}: OSGPNotesTasksSubheaderProps) => {
  return (
    <>
      <SubheaderNotification
        notification={notification}
        setNotification={setNotification}
      />
      <SubheaderControls
        onAdd={onAdd}
        onSort={onSort}
        sortOptions={sortOptions}
        sortValue={sortValue}
      />
    </>
  );
};
