// Public.

export const compareDates = (date1: string, date2: string) => {
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();

  if (time1 < time2) {
    return -1;
  } else if (time1 > time2) {
    return 1;
  } else {
    return 0;
  }
};

export const getCurrentDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return { day, month, year };
};

export const getDate = (dateTime: string) => {
  const [date] = dateTime.split(" ");
  return date;
}

export const getFormattedDate = (dateTime: string) => {
  const date = getDate(dateTime);
  const split = date.split('-');

  if(split[0].length === 4) {
    return `${split[1]}/${split[2]}/${split[0]}`;
  }

  return split.join('/');
};