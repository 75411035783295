
// Public.

export interface AppAPIHeaderConfig {
  
  accessToken: string;
  appId: string;
  clientId: string;
  envId: string;
  userId?: string;
}
export interface AppAPIQueryParamConfig {
  currentRoleName: string;
  subCategory: string;
}

export interface AppAPIConfig {
  baseUrl: string;
  header: AppAPIHeaderConfig;
  requestParam: AppAPIQueryParamConfig;
}

export enum ContentType {
  News = "News",
  Announcements = "Announcements"
}
export interface OSGPNewsAndAnnouncementsProps {
  /**
   * Optional. Custom class name.
   */
  className?: string;
  /**
   * API config data.
   */
  apiConfig: AppAPIConfig;
  /**
   * Locale selected by user.
   */
  locale: string;

  /**
   * News | Announcements
   */
  type: ContentType;

  /**
   * News | Announcements
   */
   title: string;

}
