import { DynamicFormV2Props } from "@optum-osgp-temp/ui-dynamic-forms-engine";
import { globalConstants } from "../../constants/globalConstants";

export const transformErrors: DynamicFormV2Props['transformErrors'] = (errors) => {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = globalConstants.requiredField;
    }
    return error;
  });
};
