import React from 'react';
import { GlobalNavExample } from "../components/NavigationBar";

function TestPage(){
    return(
      <div style={{marginBottom:'5px'}}>
      <GlobalNavExample/>
      <div>Test Page in developing</div>
      </div>
       );
  }
export default TestPage