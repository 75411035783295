var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DateTimePicker, } from '@optum-osgp-temp/osgp-ui-component-lib';
import React from 'react';
// function getShortDateStr(date: Date) {
//     const yy = date.getFullYear()
//     const mm = date.getMonth()
//     const dd = date.getDate()
//     const shortDate = `${yy}-${(mm < 10) ? 0 : ''}${mm + 1}-${(dd < 10) ? 0 : ''}${dd}`;
//     return shortDate;
// };
function getDate(dateVal) {
    console.log('Date time picker value ,', dateVal);
    if (!dateVal || isNaN(dateVal)) {
        return undefined;
    }
    return dateVal.toISOString();
}
export default function OSGPDateTimePicker(props) {
    console.log('OSGPDateTimePicker rendered', props);
    var ariaLabels = {};
    if (props.schema.description)
        ariaLabels['describedby'] = props.id + '-ht';
    if (props.schema.title)
        ariaLabels['labelledby'] = props.id + '-lbl';
    console.log('ariaLabels in OSGPDateTimePicker', ariaLabels);
    return (React.createElement(DateTimePicker, __assign({ id: props.id, required: props.required, placeholder: props.placeholder, onBlur: props.onBlur, onFocus: props.onFocus, error: typeof props.rawErrors === 'undefined' ? '' : props.rawErrors.toString(), value: props.value || null, className: props.className, disabled: props.disabled, readOnly: props.readonly, autoFocus: props.autofocus, label: props.label, onChange: function (value, keyboardInputValue) {
            props.onChange(getDate(value) || keyboardInputValue);
        } }, props === null || props === void 0 ? void 0 : props.uiSchema, ariaLabels)));
}
