// Libraries.

import React, { useState, useCallback } from "react";
import {
  DynamicForm,
  DynamicFormV2Props,
} from "@optum-osgp-temp/ui-dynamic-forms-engine";
import { Icon, Button, Dialog } from "@optum-osgp-temp/osgp-ui-component-lib";

// Dependencies.

import { tasksFormModal } from "../OSGPTasks/constants";
import { getDate } from "../../utilities/dates";
import { apiCall, getHeaders } from "../../utilities/serviceUtils";
import { handleValidate } from "../OSGPTasks/validations";
import { transformErrors } from "../../utilities/validations";
import { globalConstants } from "../../constants/globalConstants";
import { OSGPNotificationBanner } from "../OSGPNotificationBanner/OSGPNotificationBanner";
import { OSGPLoader } from "../OSGPLoader/OSGPLoader";
import { OSGPTaskFormModalProps } from "./OSGPTaskFormModalProps";
import formSchema from "./OSGPTasksFormModalSchema.json";
import "./styles.scss";

// Private.

const getFormData = (data: any) => ({
  taskTitle: data.taskTitle || "",
  taskDescription: data.taskDescription || "",
  ...(data.dueDate && {
    dueDate: getDate(data.dueDate),
  }),
  reminderEmails: data.reminderEmails || "",
  ...(data.reminderDate && {
    reminderDate: getDate(data.reminderDate),
  }),
});

const formContext: DynamicFormV2Props["formContext"] = {
  showErrorIcon: true,
};

// Public.

export const OSGPTaskFormModal = ({
  isOpen,
  apiConfig,
  data,
  onClose,
  onSuccess,
}: OSGPTaskFormModalProps) => {
  const isEditForm = data !== null;
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(isEditForm ? getFormData(data) : {});

  const handleSubmit: DynamicFormV2Props["onSubmit"] = useCallback(
    ({ formData }) => {
      setLoading(true);
      setFormData(formData);

      const { actionType, route, method }: any = isEditForm
        ? {
            route: `/task/${data.taskId}?currentRoleName=${apiConfig.currentRoleName}`,
            method: "put",
            actionType: "update",
          }
        : {
            route: `/task?currentRoleName=${apiConfig.currentRoleName}`,
            method: "post",
            actionType: "add",
          };

      apiCall({
        baseUrl: apiConfig.baseUrl,
        route,
        method,
        headers: getHeaders(apiConfig),
        body: {
          ...(isEditForm && data),
          taskTitle: formData.taskTitle,
          taskDescription: formData.taskDescription,
          ...(formData.dueDate && {
            dueDate: `${formData.dueDate} 23:59:59`,
          }),
          ...(formData.reminderDate && {
            reminderDate: `${formData.reminderDate} 23:59:59`,
          }),
          reminderEmails: formData.reminderEmails || "",
          status: "IN_PROGRESS",
        },
      }).then(({ isError, json }) => {
        const { message, taskId, errorCode, errorMessage } = json;

        setLoading(false);

        if (isError || errorCode || errorMessage) {
          setErrorMsg(errorMessage || globalConstants.serverError);
          return;
        }

        if (message?.toUpperCase() === "SUCCESS" || taskId) {
          onSuccess(actionType);
          onClose();
        }
      });
    },
    [apiConfig, onClose, isEditForm, data, onSuccess]
  );

  return (
    <Dialog
      className="osgp-tasks-form-dialog"
      heading={tasksFormModal[isEditForm ? 'editHeading' : 'addHeading']}
      open={isOpen}
      onClose={onClose}
      footer=" "
    >
      <p className="osgp-tasks-form-required-legend" aria-hidden>
        <Icon iconName="Asterisk" />
        Required
      </p>
      {loading && <OSGPLoader loading={loading} />}
      {errorMsg && (
        <OSGPNotificationBanner
          type="error"
          toastMsg={errorMsg}
          setToastMsg={setErrorMsg}
        />
      )}
      <DynamicForm
        enableVersion2
        schema={formSchema.schema as DynamicFormV2Props['schema']}
        uiSchema={formSchema.uiSchema}
        formContext={formContext}
        formData={formData}
        liveValidate={false}
        customValidate={handleValidate}
        transformErrors={transformErrors}
        autoComplete="off"
        onSubmit={handleSubmit}
        showErrorList={false}
      >
        <div className="osgp-tasks-form-actions">
          <Button className="submit-btn" type="submit" autoFocus>
            Submit
          </Button>
          <Button className="cancel-btn" variant="ghost" onPress={onClose}>
            Cancel
          </Button>
        </div>
      </DynamicForm>
    </Dialog>
  );
};
