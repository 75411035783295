import React, { useEffect, useRef, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid } from '@optum-osgp-temp/osgp-ui-composite-comp-lib';
import { LoadingIndicator } from "@optum-osgp-temp/osgp-ui-component-lib";
import styled from "styled-components";
import { Button,Dialog,Typography } from '@optum-osgp-temp/osgp-ui-component-lib';
import {summaryDialog} from '../Utility/reUsable';
import './pdfViewer.css';
import { pdfjs } from "react-pdf"; 
import {loadPages,drawRect,clearCanvas,tabledrawRect,checkUploadLimit,checkLimit} from '../Utility/pdfreUsable';


const DialogStyles = styled.div`
  background-color: rgb(120,120,120);

  span {
    color: #f0f;
  }
`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer=({selectedItem,tableselectedItem,onReceiveResponse,text,handleIntegrityFailed,integrityOpen, setintegrityOpen,handleFileName,model})=> {
  const inputRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [canvasData, setCanvasData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dismiss = () => setOpen(false);
  const [responseText, setResponseText] = useState("");
  const [prevCoordinates,setPrevCoordinates]=useState(null);
  const [integrityResponse, setintegrityResponse] = useState([]);

  const integrityDismiss = () => setintegrityOpen(false);  

  const columns = [   
    { field: 'checkName', headerName: 'Check Name', width:150,renderCell: (params) => (  <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>  {params.value}  </div>  )},  
    {   
      field: 'status', headerName: 'Status', width:100,  renderCell: (params) => {  
        return params.value === 'Failure' ? <CancelIcon color="error" /> : params.value === 'Success' ? <CheckCircleIcon color="success" /> : params.value;  
      },  },  
    { field: 'comments', headerName: 'Comments', width:270 ,renderCell: (params) => (  <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>  {params.value}  </div>  )},  
  ];  

  async function handleFileChange(e) {
    if (e.target.files.length === 0) {    
      return;
  }  
      const response = await checkUploadLimit();
      const file = e.target.files[0]; 
      handleFileName(file.name);
      checkLimit(response,e.target.files[0], setIsLoading, onReceiveResponse, setPdfDocument, setintegrityOpen, setintegrityResponse,handleIntegrityFailed) 
      if (handleIntegrityFailed) {  
        handleIntegrityFailed(false);    
      }    
  }     
  console.log(model);
  useEffect(() => {
  if (pdfDocument) {loadPages(pdfDocument,canvasContainerRef,setCanvasData);}
  }, [pdfDocument]);

  useEffect(() => { 
    if (selectedItem && selectedItem.cordinates) {drawRect(selectedItem,canvasData,prevCoordinates,setPrevCoordinates);}
    else{clearCanvas(prevCoordinates);}
    }, [selectedItem,canvasData]);
  
    useEffect(() => { 
      if (tableselectedItem){
        tabledrawRect(tableselectedItem,canvasData,prevCoordinates,setPrevCoordinates);
      }
      }, [tableselectedItem]);

  return (
    <>
      <input aria-label="input file" type="file" accept=".pdf" ref={inputRef} onChange={handleFileChange} className="inputFile uitkBtnBoldGhost"/>
      <Button data-testid="summarize-button" variant='ghost' onPress={() => summaryDialog(setResponseText,text,setOpen,model)} className="button" >Summarize</Button>      
              {open && (
              <DialogStyles>
                <div>
                  <Dialog heading="Summary"  open={open} onClose={dismiss} buttonName1="Done" onSubmit={dismiss} >
                    <Typography>
                    {responseText && responseText.split('\n').map((line, index) => (  
                    <React.Fragment key={index}>  
                      {line}  
                      <br />  
                    </React.Fragment>  
                    ))} 
                    </Typography>
                  </Dialog>
                </div>
              </DialogStyles>
              )}
      {isLoading ? (<LoadingIndicator size={"m"} loading={true} className="loader"/>):(<div ref={canvasContainerRef} data-testid='canvas-container' className="canvas-container"></div>)}
      {integrityOpen && (
              <DialogStyles>
                <div >
                  <Dialog className="integrityDialog" heading="Form Integrity Response"  open={integrityOpen} onClose={integrityDismiss} buttonName1="Done" onSubmit={integrityDismiss}>
                    <p style={{color:'black'}}>Following are the form integrity checks are performed :</p>
                    <div style={{ width: '100%',display:"flex" }}>  
                      <DataGrid rows={integrityResponse} columns={columns} pageSize={15} disableAdvanceFilters disableExcelExport disableToolbarFilter disableCsvExport disablePrintExport rowHeight={90}  hideFooter />  
                    </div>
                  </Dialog>
                </div>
              </DialogStyles>
              )}
    </>
    );
}
export default PdfViewer