// Libraries.

import React from 'react';

// Dependencies.

import { OSGPNotesTasksEmptyProps } from './OSGPNotesTasksEmptyProps';
import './styles.scss';

// Public.

export const OSGPNotesTasksEmpty = ({ type }: OSGPNotesTasksEmptyProps) => (
  <div className="osgp-notes-tasks-empty">There are no {type} to show.</div>
);
