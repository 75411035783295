export function encodeToBase64(url: string) {
  return window.btoa(url);
}

type HttpMethod = 'post' | 'get' | 'put' | 'delete';
interface apiCallAttributesType {
  baseUrl: string;
  route: string;
  method: HttpMethod;
  headers?: object;
  body?: any;
}

export type ApiCallReturnType = {
  status: null | number;
  isError: boolean;
  errorCaught: any;
  json: any;
};

export const apiCall = async ({
  baseUrl,
  route,
  method,
  body,
  headers = {},
}: apiCallAttributesType) => {
  const returnValue: ApiCallReturnType = {
    status: null,
    isError: false,
    errorCaught: null,
    json: {},
  };

  try {
    const response = await fetch(baseUrl + route, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    });

    returnValue.status = response.status;

    const json = await response.json();
    returnValue.json = json || {};

    return returnValue;
  } catch (error) {
    console.error(error);
    
    returnValue.isError = true;
    returnValue.errorCaught = error;

    return returnValue;
  }
};

export const getHeaders = (apiConfig: any) => {
  return {
    userId: apiConfig.userId,
    clientId: apiConfig.clientId,
    environmentId: apiConfig.envId,
    applicationId: apiConfig.appId,
    token: apiConfig.accessToken,
  };
};
