// Libraries.

import React, { useState } from 'react';
import { Dialog, Typography } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { apiCall, getHeaders } from '../../utilities/serviceUtils';
import { completeTaskModal } from './constants';

// Types.

import { OSGPCompleteTaskModalProps } from './OSGPTasksProps';
import { OSGPLoader } from '../OSGPLoader/OSGPLoader';

// Public.

export const OSGPCompleteTaskModal = (props: OSGPCompleteTaskModalProps) => {
  const { apiConfig, listItemData, onClose, onFailure, onSuccess } = props;
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);
    apiCall({
      baseUrl: apiConfig.baseUrl,
      route: `/task/${listItemData.taskId}?currentRoleName=${apiConfig.currentRoleName}`,
      method: 'put',
      headers: getHeaders(apiConfig),
      body: {
        ...listItemData,
        status: 'COMPLETED',
      },
    }).then(({ status }) => {
      setLoading(false);
      if (status === 200 || status === 201) {
        onSuccess?.('complete');
      } else {
        onFailure?.()
      }

      // Close the modal.
      onClose();
    }).catch((err: any) => {
      setLoading(false);
      onFailure?.();
    });
  };

  return (
    <Dialog
      className="osgp-complete-task-dialog"
      heading={completeTaskModal.heading}
      open
      onSave={handleSave}
      onClose={onClose}
      buttonName1={completeTaskModal.actionLabel}
    >
      {loading ? <OSGPLoader loading={loading} /> : null}
      <Typography>{completeTaskModal.description}</Typography>
    </Dialog>
  );
};
