var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { UITKCard } from "@optum-osgp-internal/osgp-uitk-component-lib";
import './styles.scss';
import { OSGPList } from "../OSGPList";
export default function OSGPListCard(props) {
    var classNames = props.subheader ? "osgp-list-card-with-subheader" : "osgp-list-card";
    if (props.className) {
        classNames = classNames + " " + props.className;
    }
    var defaultListHeight = props.subheader ? "286px" : "317px";
    return (React.createElement(UITKCard, { header: React.createElement("div", { className: "osgp-list-card-header" }, props.header), subheader: props.subheader, className: classNames, menuOptions: props.menuOptions, enableMenu: props.enableMenu, menuClickEvent: props.menuClickEvent, icon: props.icon, onClickIcon: props.onClickIcon }, props.children ? (props.children) : (React.createElement(OSGPList, __assign({ height: defaultListHeight }, props.listProps)))));
}
