(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@optum-osgp-temp/osgp-ui-component-lib"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@optum-osgp-temp/osgp-ui-component-lib", "react"], factory);
	else if(typeof exports === 'object')
		exports["OSGP-UI-Dynamic-Forms-Engine"] = factory(require("@optum-osgp-temp/osgp-ui-component-lib"), require("react"));
	else
		root["OSGP-UI-Dynamic-Forms-Engine"] = factory(root["@optum-osgp-temp/osgp-ui-component-lib"], root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__501__, __WEBPACK_EXTERNAL_MODULE__156__) => {
return 