import * as _ from "lodash";
// Filtering the option list based on search key
export var filterOptionsHelper = function (options, state, searchKey) {
    try {
        var newOptions = [];
        if (state === null || state === void 0 ? void 0 : state.inputValue) {
            filterOptions(options, searchKey, state, newOptions);
            return newOptions;
        }
        else {
            return options;
        }
    }
    catch (e) {
        return options;
    }
};
function filterOptions(options, searchKey, state, newOptions) {
    options.forEach(function (element) {
        if (_.isArray(searchKey)) {
            var isValid_1 = false;
            searchKey.forEach(function (key) {
                if (_.get(element, key)
                    .toString()
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())) {
                    isValid_1 = true;
                }
            });
            isValid_1 && newOptions.push(element);
        }
        else {
            if (_.get(element, searchKey)
                .replace(",", "")
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())) {
                newOptions.push(element);
            }
        }
    });
}
