// Libraries.

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Icon,
  OSGPListCard,
  CheckboxV2 as Checkbox,
} from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { getFullClassName } from '../../utilities/getFullClassName';
import { apiCall, getHeaders } from '../../utilities/serviceUtils';
import { OSGPNotesTasksEmpty } from '../OSGPNotesTasksEmpty';
import { OSGPNotesTasksErrorLoading } from '../OSGPNotesTasksErrorLoading';
import {
  OSGPNotesTasksSubheader,
  OSGPNotesTasksSubheaderProps,
} from '../OSGPNotesTasksSubheader';
import { NOTIFICATION_MESSAGES } from '../OSGPNotesTasksSubheader/constants';
import {
  defaultSortOptions,
  modals,
  defaultInitialSortValue,
} from './constants';
import { OSGPCompleteTaskModal } from './OSGPCompleteTaskModal';
import { OSGPTaskListItem } from './OSGPTaskListItem';
import { OSGPTasksProps } from './OSGPTasksProps';
import { OSGPTaskFormModal } from '../OSGPTaskFormModal';
import './styles.scss';

// Private.

const menuOptions = [
  {
    name: 'Add Task',
    icon: (
      <Icon
        iconName="NewWindow"
        customSize="12px"
        fill="#FFFFFF"
        className="newWindowIcon"
      />
    ),
  },
  {
    name: 'View All',
    icon: (
      <Icon
        iconName="View"
        customSize="12px"
        fill="#FFFFFF"
        className="viewIcon"
      />
    ),
  },
];

const CheckboxAction = (props: any) => {
  const { listItemData, setListItemState, listItemState, setModalState } =
    props;

  const handleClick = () => {
    if (listItemState.isChecked) {
      return;
    }

    setModalState({
      openModal: modals.COMPLETE,
      listItemData,
      setListItemState,
    });
  };

  return (
    <label>
      <Checkbox
        className="osgp-task-checkbox"
        aria-label = {listItemData?.taskTitle}
        checked={Boolean(listItemState.isChecked)}
        disabled={Boolean(listItemState.isChecked)}
        onChange={handleClick}
      />
      <span className="osgp-hidden-text">Toggle task</span>
    </label>
  );
};

// Public.

export const OSGPTasks = (props: OSGPTasksProps) => {
  const {
    className,
    apiConfig,
    noOfRecords = 5,
    initialSortValue = defaultInitialSortValue,
    sortOptions = defaultSortOptions,
  } = props;

  const [tasks, setTasks]: any = useState([]);
  const [modalState, setModalState]: any = useState({
    openModal: null,
    listItemData: null,
    setListItemState: null,
  });
  const [sortValue, setSortValue] = useState(initialSortValue);
  const [isGetError, setGetError] = useState(false);
  const [notification, setNotification] = useState<
    OSGPNotesTasksSubheaderProps['notification']
  >({
    key: 0,
    isActive: false,
    isError: false,
    message: null,
  });

  const listContainerRef = useRef<any>();
  const sortMetadata = useRef({
    isSortInitiated: false,
    previousSortValue: initialSortValue,
  });
  const errorListItemStateSetter = useRef(new Map());

  const fullClassName = getFullClassName(['osgp-tasks', className]);
  const isCompleteTaskModalOpen = modalState.openModal === modals.COMPLETE;
  const isFormModalOpen = modalState.openModal === modals.FORM;

  const handleAdd = () => {
    setModalState({
      openModal: modals.FORM,
      listItemData: null,
      setListItemState: null,
    });
  };

  const handleSort = (name: string, value: string) => {
    sortMetadata.current = {
      isSortInitiated: true,
      previousSortValue: sortValue,
    };

    setSortValue(value);
  };

  const handleMenuItemClick = (menuName: string) => {
    if (menuName === 'Add Task') {
      setModalState({
        openModal: modals.FORM,
        listItemData: null,
        setListItemState: null,
      });
      return;
    } else if (menuName === 'View All') {
      props.onViewAllClick?.();
    }
  };

  const handleSuccess = (type: 'add' | 'complete' | 'update') => {
    let messageKey = 'addSuccess';

    if (type === 'complete') {
      // Mark task as completed and reset compete error.
      modalState.setListItemState?.({
        isChecked: true,
        isCompleteError: false,
      });
      messageKey = 'completeSuccess';
    } else if (type === 'update') {
      messageKey = 'updateSuccess';
    }

    // Show success notification.
    setNotification((prev) => ({
      isActive: true,
      key: prev.key + 1,
      message: NOTIFICATION_MESSAGES[messageKey],
      isError: false,
    }));

    // Get new set of tasks.
    getTasks();
  };

  const handleCompleteFailure = () => {
    // Show item level error message.
    modalState.setListItemState?.((prev: any) => ({
      ...prev,
      isCompleteError: true,
    }));

    // Show failure notification.
    setNotification((prev) => ({
      isActive: true,
      key: prev.key + 1,
      message: NOTIFICATION_MESSAGES.error,
      isError: true,
    }));
  };

  const handleModalClose = () => {
    // Close the open modal.
    setModalState({
      openModal: null,
      listItemData: null,
      setListItemState: null,
    });
  };

  const ListItemWithProps = useCallback((listItemProps: any) => {
    return (
      <OSGPTaskListItem
        {...listItemProps}
        errorListItemStateSetter={errorListItemStateSetter}
        setModalState={setModalState}
      />
    );
  }, []);

  const CheckboxActionWithProps = useCallback((checkboxActionProps: any) => {
    return (
      <CheckboxAction {...checkboxActionProps} setModalState={setModalState} />
    );
  }, []);

  const getTasks = useCallback(() => {
    const { isSortInitiated, previousSortValue } = sortMetadata.current;

    // If sort is initiated and previous sort is same as the current one,
    // it means the sort failed and sort state was restored, so return.
    if (isSortInitiated && previousSortValue === sortValue) {
      sortMetadata.current.isSortInitiated = false;
      return;
    }

    setGetError(false);
    apiCall({
      baseUrl: apiConfig.baseUrl,
      route: `/task?status=IN_PROGRESS&size=${noOfRecords}&sort=${sortValue}&currentRoleName=${apiConfig.currentRoleName}`,
      method: 'get',
      headers: getHeaders(apiConfig),
    }).then(({ isError, status, json }) => {
      const { errorCode, errorMessage, _embedded } = json;

      if (isError || errorCode || errorMessage || status !== 200) {
        // If there was an error in the API call because of sort value
        // had changed, display error notification in subheader.
        if (isSortInitiated) {
          setNotification((prev) => ({
            ...prev,
            key: prev.key + 1,
            isActive: true,
            isError: true,
            message: NOTIFICATION_MESSAGES.error,
          }));
          // Restore previous sort value.
          setSortValue(previousSortValue);
          return;
        }

        setGetError(true);
        return;
      }

      // If API call was due to sort.
      if (isSortInitiated) {
        sortMetadata.current.isSortInitiated = false;

        // Reset scroll position.
        if (listContainerRef.current) {
          listContainerRef.current.scrollTop = 0;
        }
      }

      // Reset all the list items state which had an error.
      errorListItemStateSetter.current.forEach((value, key) => {
        value({});
        errorListItemStateSetter.current.delete(key);
      });

      // Update tasks.
      setTasks(_embedded?.task ?? []);
    });
  }, [apiConfig, noOfRecords, sortValue]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return (
    <>
      <OSGPListCard
        header="Tasks"
        subheader={
          isGetError ? null : (
            <OSGPNotesTasksSubheader
              notification={notification}
              onAdd={handleAdd}
              onSort={handleSort}
              setNotification={setNotification}
              sortOptions={sortOptions}
              sortValue={sortValue}
            />
          )
        }
        enableMenu
        menuOptions={menuOptions}
        menuClickEvent={handleMenuItemClick}
        className={fullClassName}
        listProps={{
          data: tasks,
          dataKeyName: 'taskId',
          listItemTemplate: ListItemWithProps,
          secondaryAction: CheckboxActionWithProps,
          height: notification.isActive ? '256px' : '286px',
          containerRef: listContainerRef,
        }}
      >
        {isGetError ? (
          <OSGPNotesTasksErrorLoading onReload={getTasks} />
        ) : !tasks?.length ? (
          <OSGPNotesTasksEmpty type="Tasks" />
        ) : null}
      </OSGPListCard>
      {isCompleteTaskModalOpen && (
        <OSGPCompleteTaskModal
          apiConfig={apiConfig}
          listItemData={modalState.listItemData}
          onClose={handleModalClose}
          onFailure={handleCompleteFailure}
          onSuccess={handleSuccess}
        />
      )}
      {isFormModalOpen && (
        <OSGPTaskFormModal
          isOpen={isFormModalOpen}
          apiConfig={apiConfig}
          data={modalState.listItemData}
          onClose={handleModalClose}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};
