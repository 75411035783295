var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Icon, TextInput } from "@optum-osgp-temp/osgp-ui-component-lib";
import { useRef } from "react";
export default function OSGPDollar(props) {
    var _a;
    var uiOptions = (_a = props.uiSchema) === null || _a === void 0 ? void 0 : _a["ui:options"];
    var ariaLabels = {};
    var _b = useState(props.value || ""), value = _b[0], setValue = _b[1];
    var ref = useRef(null);
    var formatDollar = function (value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value)
            return value;
        // clean the input for any non-digit values.
        // const ssn = value.replace(/[^\d]/g, '');
        var amount = value.replace(/[^0-9.]/g, "");
        return amount;
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { id: "dollar-input-icons" },
            React.createElement("div", { className: "icon" },
                React.createElement(Icon, { iconName: "DollarSign", fill: "#434343" })),
            React.createElement(TextInput, __assign({ id: props === null || props === void 0 ? void 0 : props.id, value: value, required: props === null || props === void 0 ? void 0 : props.required, disabled: props === null || props === void 0 ? void 0 : props.disabled, readOnly: props === null || props === void 0 ? void 0 : props.readonly, autoFocus: props === null || props === void 0 ? void 0 : props.autofocus, placeholder: props === null || props === void 0 ? void 0 : props.placeholder, onBlur: function (event) { props.onBlur(props.id, event.target.value); }, onFocus: function (event) { props.onFocus(props.id, event.target.value); }, ref: ref, onChange: function (event) {
                    var value = event.target.value;
                    // don't allow to start from 0
                    // if (value.charAt(0) == ".") value = "0." + value.slice(1, value.length)
                    var output = value;
                    var occurrences = __spreadArray([], output.matchAll(/[/.]/g), true);
                    if (occurrences.length > 1) {
                        // remove all occurances of dot except first 
                        output = value.split('.');
                        output = output.shift() + '.' + output.join('');
                    }
                    // remove all non numbers except dot
                    var formattedValue = formatDollar(output);
                    setValue(formattedValue);
                    props.onChange(formattedValue);
                }, error: typeof props.rawErrors === "undefined" ? "" : props.rawErrors.toString(), className: "input-field" }, ariaLabels, uiOptions))));
}
