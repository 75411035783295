import { createTheme } from '@mui/material';
//import OSGPTheme from "../types/OSGPTheme";
import { getSizeInPx } from '../utils/cssUtils';
//Add more mappings as when needed/required.
export default function getMUITheme(currentTheme) {
    console.log('current theme  : ', currentTheme);
    var MUIOptumTheme = createTheme({
        palette: {
            primary: {
                main: currentTheme.color.text.brand.value,
            },
            warning: {
                main: currentTheme.color.semantic.warning.base.value,
            },
            error: {
                main: currentTheme.color.semantic.error.base.value,
            },
            success: {
                main: currentTheme.color.semantic.success.base.value,
            },
            info: {
                main: currentTheme.color.semantic.info.base.value,
            },
            text: {
                primary: currentTheme.color.text.base.value,
            },
            background: {
                default: currentTheme.color.background.base.value,
                paper: currentTheme.color.background.form.element.base.value,
            },
        },
        typography: {
            fontFamily: currentTheme.font.family.base.value,
            fontSize: getSizeInPx(currentTheme.font.size.body.base.value),
            fontWeightBold: Number(currentTheme.font.weight.strong.value),
            fontWeightLight: Number(currentTheme.font.weight.light.value),
            fontWeightMedium: Number(currentTheme.font.weight.medium.value),
            fontWeightRegular: Number(currentTheme.font.weight.base.value),
            h1: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h1.value),
                fontFamily: currentTheme.font.family.heading.h1.value,
                fontWeight: Number(currentTheme.font.weight.heading.h1.value),
            },
            h2: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h2.value),
                fontFamily: currentTheme.font.family.heading.h2.value,
                fontWeight: Number(currentTheme.font.weight.heading.h2.value),
            },
            h3: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h3.value),
                fontFamily: currentTheme.font.family.heading.h3.value,
                fontWeight: Number(currentTheme.font.weight.heading.h3.value),
            },
            h4: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h4.value),
                fontFamily: currentTheme.font.family.heading.h4.value,
                fontWeight: Number(currentTheme.font.weight.heading.h4.value),
            },
            h5: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h5.value),
                fontFamily: currentTheme.font.family.heading.h5.value,
                fontWeight: Number(currentTheme.font.weight.heading.h5.value),
            },
            h6: {
                fontSize: getSizeInPx(currentTheme.font.size.heading.h6.value),
                fontFamily: currentTheme.font.family.heading.h6.value,
                fontWeight: Number(currentTheme.font.weight.heading.h6.value),
            },
            button: {
                fontSize: getSizeInPx(currentTheme.font.size.body.base.value),
                fontFamily: currentTheme.font.family.base.value,
                fontWeight: Number(currentTheme.font.weight.base.value),
            },
            caption: {
                fontSize: getSizeInPx(currentTheme.font.size.body.small.value),
                fontFamily: currentTheme.font.family.base.value,
                fontWeight: Number(currentTheme.font.weight.base.value),
            },
            overline: {
                fontSize: getSizeInPx(currentTheme.font.size.body.small.value),
                fontFamily: currentTheme.font.family.base.value,
                fontWeight: Number(currentTheme.font.weight.base.value),
            }
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        marginTop: "".concat(currentTheme.spacing.xxs.value),
                        borderRadius: "".concat(currentTheme.border.radius.form.element.value),
                        backgroundColor: "".concat(currentTheme.color.background.form.element.base.value),
                        boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.accessible.value),
                        boxSizing: "border-box",
                        color: "".concat(currentTheme.color.text.brand.value),
                        fontWeight: currentTheme.font.weight.base.value,
                        fontFamily: "".concat(currentTheme.font.family.base.value),
                        textTransform: 'none',
                        border: 'none',
                        outline: 'none',
                        boxsizing: 'border-box',
                        transition: "".concat(currentTheme.animation.duration.animate.fast.value),
                        transitionProperty: 'border, box-shadow',
                        '& input': {
                            padding: "".concat(currentTheme.spacing.sm.value),
                            fontSize: currentTheme.font.size.body.base.value,
                            fontWeight: currentTheme.font.weight.base.value,
                            fontFamily: currentTheme.font.family.base.value,
                        },
                        '&:hover fieldset, &:focus fieldset': {
                            border: '0',
                        },
                        '&:hover': {
                            boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.form.element.active.value),
                        },
                        '&:focus-within': {
                            backgroundColor: "".concat(currentTheme.color.background.form.element.base.value),
                            boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.form.element.active.value),
                        },
                        '&>div:hover fieldset, &>div.Mui-focused fieldset': {
                            border: '0',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: "".concat(currentTheme.color.background.form.element.base.value),
                        color: "".concat(currentTheme.color.text.brand.value),
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.accessible.value),
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        boxSizing: 'border-box',
                        flexShrink: 0,
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                        fontWeight: currentTheme.font.weight.base.value,
                        fontFamily: "".concat(currentTheme.font.family.base.value),
                        fontSize: getSizeInPx(currentTheme.font.size.body.base.value),
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                        borderRadius: getSizeInPx(currentTheme.border.radius.base.value),
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.accessible.value),
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        borderRadius: getSizeInPx(currentTheme.border.radius.base.value),
                        boxShadow: "".concat(currentTheme.boxshadow.form.base.value, " ").concat(currentTheme.color.border.accessible.value),
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                        fontWeight: currentTheme.font.weight.base.value,
                        fontFamily: "".concat(currentTheme.font.family.base.value),
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        color: "".concat(currentTheme.color.text.brand.value),
                        fontWeight: currentTheme.font.weight.base.value,
                        fontFamily: "".concat(currentTheme.font.family.base.value),
                        fontSize: currentTheme.font.size.hero.value,
                    },
                },
            },
        },
        shape: {
            borderRadius: getSizeInPx(currentTheme.border.radius.base.value),
        },
    });
    return MUIOptumTheme;
}
