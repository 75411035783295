// Libraries.

import React, { createContext, useContext } from 'react';
import {
  useGridSelector,
  useGridApiContext,
  gridPageSelector,
  useGridRootProps,
  gridPageSizeSelector,
  gridFilteredTopLevelRowCountSelector,
} from '@mui/x-data-grid-premium';
import { Button } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { GridPaginationGoTo } from './GridPaginationGoTo';
import { GridPaginationProps } from './DataGridProps';

// Private.

const GridPaginationContext = createContext<GridPaginationProps>({});

// Public.

export const GridPaginationContextProvider = GridPaginationContext.Provider;

export const GridPagination = () => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const { enableGoToPage } = useContext(GridPaginationContext);
  const page = useGridSelector(apiRef, gridPageSelector);
  const topLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  );
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = rootProps.rowCount ?? topLevelRowCount ?? 0;
  const pageCount = Math.ceil(rowCount / pageSize);
  const pageSizeOptions = rootProps.pageSizeOptions ?? [];
  const isPreviousDisabled = page === 0;
  const isNextDisabled = page === pageCount - 1;

  const handleNext = () => {
    apiRef.current.setPage(page + 1);
  };

  const handlePrevious = () => {
    apiRef.current.setPage(page - 1);
  };

  const handlePageSizeChange = (option: number) => {
    apiRef.current.setPageSize(option);
  };

  if (!topLevelRowCount) {
    return null;
  }

  return (
    <div className="osgp-data-grid-pagination-container">
      <div className="osgp-data-grid-pagination-row-options">
        <span className="osgp-data-grid-pagination-showing">Showing</span>
        <div
          className="osgp-data-grid-pagination-row-options-group"
        >
          {pageSizeOptions.map((option) => (
            <button
              key={option}
              className={option === pageSize ? 'osgp-data-grid-pagination-row-options-button osgp-data-grid-pagination-row-options-button-active-button' :
                'osgp-data-grid-pagination-row-options-button'
              }
              onClick={() => handlePageSizeChange(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
      <div className="osgp-data-grid-pagination-controls">
        <span className="osgp-data-grid-pagination-information">
          Displaying page: {page + 1} of {pageCount}
        </span>
        {enableGoToPage ? <GridPaginationGoTo pageCount={pageCount} /> : null}
        <Button
          variant="ghost-alternative"
          onPress={handlePrevious}
          disabled={isPreviousDisabled}
        >
          Previous
        </Button>
        <Button
          variant="ghost-alternative"
          onPress={handleNext}
          disabled={isNextDisabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
