// Libraries.

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  gridFilterableColumnDefinitionsSelector,
  useGridApiContext,
  useGridSelector,
  gridFilterActiveItemsLookupSelector,
  GridColDef,
  GridSingleSelectColDef,
  gridFilterModelSelector,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';
import { DynamicForm, DynamicFormV2Props } from '@optum-osgp-temp/ui-dynamic-forms-engine';
import { Button } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { GridBasicFilterPanelProps } from './DataGridProps';

// Private.

const TO_DATE_TYPE = 'to-date';
const FROM_DATE_TYPE = 'from-date';
const TO_DATE_TIME_TYPE = 'to-date_time';
const FROM_DATE_TIME_TYPE = 'from-date_time';

const TO_DATE_POST_FIX = '_to_date';
const FROM_DATE_POST_FIX = '_from_date';
const TO_DATE_TIME_POST_FIX = '_to_date_time';
const FROM_DATE_TIME_POST_FIX = '_from_date_time';

const FILTER_OPERATIONS: any = {
  [TO_DATE_TYPE]: 'onOrBefore',
  [FROM_DATE_TYPE]: 'onOrAfter',
  [TO_DATE_TIME_TYPE]: 'onOrBefore',
  [FROM_DATE_TIME_TYPE]: 'onOrAfter',
  string: 'contains',
  boolean: 'is',
  number: 'contains',
  singleSelect: 'is',
};

const formContext: DynamicFormV2Props['formContext'] = {
  showErrorIcon: true,
};

export const getSchemaEnum = (type?: string, valueOptions?: any) => {
  if (type === 'singleSelect') {
    return valueOptions || [];
  }

  if (type === 'boolean') {
    return ['true', 'false'];
  }

  return undefined;
};

export const getSchema = (
  field: GridColDef['field'],
  headerName: GridColDef['headerName'],
  type: string | undefined,
  valueOptions: GridSingleSelectColDef['valueFormatter'],
  customLabel: string | undefined
) => {
  if (type === FROM_DATE_TYPE || type === TO_DATE_TYPE) {
    const titlePostfix = type === FROM_DATE_TYPE ? '- From' : '- To';
    const title = customLabel || `${headerName || field} ${titlePostfix}`;

    return {
      type: 'string',
      title,
      format: 'date',
    };
  }

  if (type === FROM_DATE_TIME_TYPE || type === TO_DATE_TIME_TYPE) {
    const titlePostfix = type === FROM_DATE_TIME_TYPE ? '- From' : '- To';
    const title = customLabel || `${headerName || field} ${titlePostfix}`;

    return {
      type: 'string',
      title,
      format: 'datetime',
    };
  }

  return {
    type: type === 'number' ? 'number' : 'string',
    title: customLabel || headerName || field,
    enum: getSchemaEnum(type, valueOptions),
  };
};

export const getUISchema = (type: string | undefined) => {
  return {
    gridPositionClass: 'col-m-4 col-l-3 filter-grid',
    ...((type === 'singleSelect' || type === 'boolean') && {
      'ui:placeholder': 'Select',
    }),
    ...(type === 'date' && {
      PopperProps: {
        placement: 'bottom-start',
        disablePortal: true,
      },
      'ui:placeholder': 'MM/DD/YYYY',
    }),
    ...(type === 'number' && {
      'ui:options': {
        type: 'number',
      },
    }),
    ...(type === 'dateTime' && {
      classNames: 'osgp-data-grid-date-time-picker',
    }),
  };
};

export const getFilterModelFromFormData = (
  formData: any,
  apiRef: any,
  formDataToFilterModelMapping: any
) => {
  const existingFilterModel = gridFilterModelSelector(apiRef);

  const items = Object.entries(formData).map(([filterKey, filterValue]) => {
    const { field, operator } = formDataToFilterModelMapping[filterKey];
    return {
      id: Math.round(Math.random() * 1e5),
      field,
      operator,
      value: filterValue,
    };
  });

  return {
    ...existingFilterModel,
    logicOperator: GridLogicOperator.And,
    items,
  };
};

export const getFormDataFromFilterModelItems = (
  filterModelItems: any[],
  filterModelToFormDataMapping: any
) => {
  const formData: any = {};

  filterModelItems.forEach(({ field, operator, value }) => {
    const { type } = filterModelToFormDataMapping?.[field] || {};
    const isDate = type === 'date';
    const isDateTime = type === 'dateTime';

    let formKey = field;

    if (isDate) {
      const isFromDate = operator === FILTER_OPERATIONS[FROM_DATE_TYPE];
      formKey = formKey.concat(
        isFromDate ? FROM_DATE_POST_FIX : TO_DATE_POST_FIX
      );
    }

    if (isDateTime) {
      const isFromDateTime =
        operator === FILTER_OPERATIONS[FROM_DATE_TIME_TYPE];
      formKey = formKey.concat(
        isFromDateTime ? FROM_DATE_TIME_POST_FIX : TO_DATE_TIME_POST_FIX
      );
    }

    formData[formKey] = value;
  });

  return formData;
};

// Public.

export const GridBasicFilterPanel = ({
  customBasicFilterLabels,
}: GridBasicFilterPanelProps) => {
  const submitRef = useRef<any>(null);
  const dynamicFormKey = useRef(0);
  const [formData, setFormData] = useState(null);

  const apiRef = useGridApiContext();
  const filterableColumns = useGridSelector(
    apiRef,
    gridFilterableColumnDefinitionsSelector
  );
  const filterActiveItems = useGridSelector(
    apiRef,
    gridFilterActiveItemsLookupSelector
  );
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const isAnyFilterApplied = useMemo(
    () => Object.values(filterActiveItems).some((array) => array.length),
    [filterActiveItems]
  );

  const [
    schema,
    uiSchema,
    formDataToFilterModelMapping,
    filterModelToFormDataMapping,
  ] = useMemo(() => {
    if (!filterableColumns) {
      return [undefined, undefined];
    }

    const properties = filterableColumns.reduce(
      (formProperties: any, filter) => {
        const { field, headerName, type, filterable, valueOptions } =
          filter as any;

        if (!filterable || type === 'actions') {
          return formProperties;
        }

        const newFormProperties = { ...formProperties };

        newFormProperties.filterModelToFormDataMapping[field] = {
          type,
        };

        if (type === 'date') {
          const fromKey = `${field}${FROM_DATE_POST_FIX}`;
          newFormProperties.schema.properties[fromKey] = getSchema(
            field,
            headerName,
            FROM_DATE_TYPE,
            valueOptions,
            customBasicFilterLabels?.[fromKey]
          );
          newFormProperties.uiSchema[fromKey] = getUISchema(type);
          newFormProperties.formDataToFilterModelMapping[fromKey] = {
            type,
            field,
            operator: FILTER_OPERATIONS[FROM_DATE_TYPE],
          };

          const toKey = `${field}${TO_DATE_POST_FIX}`;
          newFormProperties.schema.properties[toKey] = getSchema(
            field,
            headerName,
            TO_DATE_TYPE,
            valueOptions,
            customBasicFilterLabels?.[toKey]
          );
          newFormProperties.uiSchema[toKey] = getUISchema(type);
          newFormProperties.formDataToFilterModelMapping[toKey] = {
            type,
            field,
            operator: FILTER_OPERATIONS[TO_DATE_TYPE],
          };
        } else if (type === 'dateTime') {
          const fromKey = `${field}${FROM_DATE_TIME_POST_FIX}`;
          newFormProperties.schema.properties[fromKey] = getSchema(
            field,
            headerName,
            FROM_DATE_TIME_TYPE,
            valueOptions,
            customBasicFilterLabels?.[fromKey]
          );
          newFormProperties.uiSchema[fromKey] = getUISchema(type);
          newFormProperties.formDataToFilterModelMapping[fromKey] = {
            type,
            field,
            operator: FILTER_OPERATIONS[FROM_DATE_TIME_TYPE],
          };

          const toKey = `${field}${TO_DATE_TIME_POST_FIX}`;
          newFormProperties.schema.properties[toKey] = getSchema(
            field,
            headerName,
            TO_DATE_TIME_TYPE,
            valueOptions,
            customBasicFilterLabels?.[toKey]
          );
          newFormProperties.uiSchema[toKey] = getUISchema(type);
          newFormProperties.formDataToFilterModelMapping[toKey] = {
            type,
            field,
            operator: FILTER_OPERATIONS[TO_DATE_TIME_TYPE],
          };
        } else {
          newFormProperties.schema.properties[field] = getSchema(
            field,
            headerName,
            type,
            valueOptions,
            customBasicFilterLabels?.[field]
          );
          newFormProperties.uiSchema[field] = getUISchema(type);
          newFormProperties.formDataToFilterModelMapping[field] = {
            type: type || 'string',
            field,
            operator: FILTER_OPERATIONS[type || 'string'],
          };
        }

        return newFormProperties;
      },
      {
        schema: { type: 'object', properties: {} },
        uiSchema: {},
        formDataToFilterModelMapping: {},
        filterModelToFormDataMapping: {},
      }
    );

    return [
      properties.schema,
      properties.uiSchema,
      properties.formDataToFilterModelMapping,
      properties.filterModelToFormDataMapping,
    ];
  }, [customBasicFilterLabels, filterableColumns]);

  useEffect(
    () =>
      setFormData(
        getFormDataFromFilterModelItems(
          filterModel?.items ?? [],
          filterModelToFormDataMapping
        )
      ),
    [filterModel?.items, filterModelToFormDataMapping]
  );

  const handleSubmit = useCallback(
    (formState) => {
      const filterModel = getFilterModelFromFormData(
        formState.formData,
        apiRef,
        formDataToFilterModelMapping
      );

      apiRef.current.setFilterModel(filterModel);
      setFormData(formState.formData);
    },
    [apiRef, formDataToFilterModelMapping]
  );

  const handleClear = useCallback(() => {
    const currentFilterModel = gridFilterModelSelector(apiRef);
    apiRef.current.setFilterModel({
      ...currentFilterModel,
      items: [],
    });
    submitRef.current?.focus();
    dynamicFormKey.current += 1;
  }, [apiRef]);

  return (
    <div className="osgp-data-grid-basic-filter-panel">
      <DynamicForm
        key={dynamicFormKey.current}
        enableVersion2
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        autoComplete="off"
        showErrorList={false}
        onSubmit={handleSubmit}
        formContext={formContext}
      >
        <Button
          buttonRef={submitRef}
          type="submit"
          className="osgp-pagination-table-with-filter-apply-filter-button"
        >
          Apply Filters
        </Button>
        {isAnyFilterApplied ? (
          <Button
            className="osgp-pagination-table-with-filter-clear-filter-button"
            type="button"
            variant="ghost-alternative"
            onPress={handleClear}
          >
            Clear Filters
          </Button>
        ) : null}
      </DynamicForm>
    </div>
  );
};
