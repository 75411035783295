// Libraries.

import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  DataGridPremium,
  useGridApiRef,
  gridExpandedRowCountSelector,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { Icon, LoadingIndicator} from '@optum-osgp-temp/osgp-ui-component-lib';

// TODO: Eager loading is temporarily used to include the bundle in the main bundle.
// Explore build setup where additional files can be exposed to consumer.
import(/* webpackMode: "eager" */ 'exceljs');

// Dependencies.

import { DataGridProps } from './DataGridProps';
import {
  gridDateFilterOperators,
  gridNumericFilterOperators,
} from './gridFilterOperations';
import {
  GridPagination,
  GridPaginationContextProvider,
} from './GridPagination';
import { GridToolbar, GridToolbarContextProvider } from './GridToolbar';
import { GridEditDateCell } from './GridEditDateCell';
import { GridFilterPanel } from './GridFilterPanel';
import { GridExportsContextProvider } from './GridExports';
import './styles.scss';
import { GridEditInputNumberCell } from './GridEditInputNumberCell';

// Private.

const MemoizedDataGrid = memo<DataGridPremiumProps>(DataGridPremium);

const ColumnSortedAscendingIcon = () => (
  <Icon iconName="SortAscending" fill="#002677" />
);

const ColumnSortedDescendingIcon = () => (
  <Icon iconName="SortDescending" fill="#002677" />
);

const ColumnUnsortedIcon = () => (
  <div className="osgp-data-grid-unsorted-arrow-icon-container">
    <Icon iconName="OSGPUnsortedArrow" fill="#002677" />
  </div>
);

const ColumnFilteredIcon = () => (
  <Icon iconName="OSGPFilter" fill="#909090" width="14px" height="14px" />
);

const GridEmpty = () => (
  <div className="osgp-data-grid-empty-message">
    There are no items in the list
  </div>
);

const GridLoadingOverlay = () => (
  <LoadingIndicator loading size="m" displayOverlay />
);

const defaultPageSizeOptions = [25, 50, 100];

const defaultSlotProps: DataGridProps['slotProps'] = {
  baseSelect: {
    native: false,
    MenuProps: {
      className: 'osgp-data-grid-select-menu',
    },
  },
  baseSelectOption: {
    onMouseOver: (e: any) => {
      e.target.focus?.();
    },
  } as any,
  baseTextField: {
    autoComplete: 'off',
  },
  baseCheckbox: {
    className: 'osgp-data-grid-base-checkbox',
    disableRipple: true,
    checkedIcon: (
      <span
        aria-hidden
        className="osgp-data-grid-base-checkbox-icon-container osgp-data-grid-base-checkbox-state-checked"
      >
        <Icon iconName="CheckmarkBold" fill="#FFFFFF" />
      </span>
    ),
    icon: (
      <span
        aria-hidden
        className="osgp-data-grid-base-checkbox-icon-container osgp-data-grid-base-checkbox-state-unchecked"
      />
    ),
    indeterminateIcon: (
      <span
        aria-hidden
        className="osgp-data-grid-base-checkbox-icon-container osgp-data-grid-base-checkbox-state-intermediate"
      >
        <Icon iconName="Subtract" fill="#FFFFFF" stroke="#FFFFFF" />
      </span>
    ),
  },
};

const defaultSlots: DataGridProps['slots'] = {
  columnSortedAscendingIcon: ColumnSortedAscendingIcon,
  columnSortedDescendingIcon: ColumnSortedDescendingIcon,
  columnUnsortedIcon: ColumnUnsortedIcon,
  noRowsOverlay: GridEmpty,
  noResultsOverlay: GridEmpty,
  pagination: GridPagination,
  toolbar: GridToolbar,
  filterPanelAddIcon: () => null,
  filterPanelRemoveAllIcon: () => null,
  loadingOverlay: GridLoadingOverlay,
  filterPanel: GridFilterPanel,
  columnFilteredIcon: ColumnFilteredIcon,
};

// Public.

export const DataGrid = ({
  apiRef: givenApiRef,
  autoHeight = true,
  className,
  slots: givenSlots,
  columns: givenColumns,
  csvExportOptions,
  customBasicFilterLabels,
  disableAdvanceFilters = false,
  disableColumnMenu = true,
  disableColumnFilter: givenDisableColumnFilter,
  disableColumnResize = true,
  disableExcelExport = false,
  disableCsvExport = false,
  disablePrintExport = false,
  enableGoToPage = false,
  excelExportOptions,
  columnHeaderHeight = 45,
  hideFooterRowCount = true,
  hideFooterSelectedRowCount = true,
  disableToolbarFilter,
  hideToolbarRowCount,
  initialState: givenInitialState,
  printExportOptions,
  pagination = true,
  rowHeight = 49,
  pageSizeOptions = defaultPageSizeOptions,
  slotProps: givenSlotProps,
  excelExportLabel = 'Export to Excel',
  csvExportLabel = 'Export to CSV',
  printExportLabel = 'Print',
  exportMenuLabel = 'Export',
  onExport,
  exportMode = 'client',
  ...otherProps
}: DataGridProps) => {
  const defaultApiRef = useGridApiRef();
  const apiRef = givenApiRef || defaultApiRef;

  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const disableColumnFilter = disableAdvanceFilters
    ? true
    : givenDisableColumnFilter;

  const columns = useMemo(
    () =>
      givenColumns.map((column) => {
        if (column.type === 'date') {
          return {
            filterOperators: gridDateFilterOperators,
            renderEditCell: GridEditDateCell,
            ...column,
          };
        }

        if (column.type === 'number' || column.type === "decimal") {
          return {
            filterOperators: gridNumericFilterOperators,
            renderEditCell: GridEditInputNumberCell,
            ...column,
          };
        }

        return column;
      }),
    [givenColumns]
  );

  const slots = useMemo<DataGridProps['slots']>(
    () => ({ ...defaultSlots, ...givenSlots }),
    [givenSlots]
  );

  const slotProps = useMemo<DataGridProps['slotProps']>(
    () => ({ ...defaultSlotProps, ...givenSlotProps }),
    [givenSlotProps]
  );

  const initialState: DataGridProps['initialState'] = useMemo(() => {
    const { pagination: givenPagination, ...otherInitialState } =
      givenInitialState || {};

    return {
      pagination: {
        paginationModel: {
          page: 0,
          pageSize: pageSizeOptions?.[0] || 25,
        },
        ...givenPagination,
      },
      ...otherInitialState,
    };
  }, [givenInitialState, pageSizeOptions]);

  const fullClassName = [
    'osgp-data-grid',
    className,
    isTableEmpty && 'osgp-data-grid-empty',
  ]
    .filter(Boolean)
    .join(' ');

  const gridExportContextValue = useMemo(
    () => ({
      csvExportOptions,
      disableExcelExport,
      disableCsvExport,
      disablePrintExport,
      excelExportOptions,
      hideToolbarRowCount,
      printExportOptions,
      excelExportLabel,
      csvExportLabel,
      printExportLabel,
      exportMenuLabel,
      onExport,
      exportMode,
    }),
    [
      csvExportOptions,
      disableExcelExport,
      disableCsvExport,
      disablePrintExport,
      excelExportOptions,
      hideToolbarRowCount,
      printExportOptions,
      excelExportLabel,
      csvExportLabel,
      printExportLabel,
      exportMenuLabel,
      onExport,
      exportMode,
    ]
  );

  const gridPaginationContextValue = useMemo(
    () => ({ enableGoToPage }),
    [enableGoToPage]
  );

  const gridToolbarContextValue = useMemo(
    () => ({
      customBasicFilterLabels,
      disableToolbarFilter,
      disableAdvanceFilters,
    }),
    [customBasicFilterLabels, disableAdvanceFilters, disableToolbarFilter]
  );

  useEffect(() => {
    const unsubscribe = apiRef.current?.subscribeEvent('stateChange', () => {
      const visibleRowCount = gridExpandedRowCountSelector(apiRef);
      setIsTableEmpty(visibleRowCount === 0);
    });

    return () => {
      unsubscribe?.();
    };
  }, [apiRef]);

  const returnEmptyText = (isTableEmpty:boolean) => {
    if (isTableEmpty) {
        return "There are no items in the list"
    }
  }
  
  return (
    <div aria-label={returnEmptyText(isTableEmpty)}>
    <GridPaginationContextProvider value={gridPaginationContextValue}>
      <GridToolbarContextProvider value={gridToolbarContextValue}>
        <GridExportsContextProvider value={gridExportContextValue}>
          <MemoizedDataGrid
            initialState={initialState}
            columns={columns}
            apiRef={apiRef}
            className={fullClassName}
            disableColumnMenu={disableColumnMenu}
            disableColumnResize={disableColumnResize}
            slots={slots}
            autoHeight={autoHeight}
            columnHeaderHeight={columnHeaderHeight}
            rowHeight={rowHeight}
            hideFooterRowCount={hideFooterRowCount}
            hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            pageSizeOptions={pageSizeOptions}
            pagination={pagination}
            slotProps={slotProps}
            disableColumnFilter={disableColumnFilter}
            {...otherProps}
          />
        </GridExportsContextProvider>
      </GridToolbarContextProvider>
    </GridPaginationContextProvider>
    </div>
  );
};
