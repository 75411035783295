// Libraries.

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Icon,
  ListItemButton,
  OSGPListCard,
} from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { getFullClassName } from '../../utilities/getFullClassName';
import { apiCall, getHeaders } from '../../utilities/serviceUtils';
import {
  OSGPNotesTasksSubheader,
  OSGPNotesTasksSubheaderProps,
} from '../OSGPNotesTasksSubheader';
import { NOTIFICATION_MESSAGES } from '../OSGPNotesTasksSubheader/constants';
import { OSGPNoteFormModal } from '../OSGPNoteFormModal';
import { OSGPNotesTasksEmpty } from '../OSGPNotesTasksEmpty';
import { OSGPNotesTasksErrorLoading } from '../OSGPNotesTasksErrorLoading';
import { OSGPNoteListItem } from './OSGPNoteListItem';
import {
  defaultSortOptions,
  modals,
  defaultInitialSortValue,
} from './constants';
import { OSGPDeleteNoteModal } from './OSGPDeleteNoteModal';
import { OSGPNotesProps } from './OSGPNotesProps';
import './styles.scss';

// Private.

const DeleteAction = ({
  listItemData,
  setListItemState,
  setModalState,
}: any) => {
  const handleClick = () => {
    setModalState({
      openModal: modals.DELETE,
      listItemData,
      setListItemState,
    });
  };

  return (
    <ListItemButton
      aria-label="Delete list item"
      className="osgp-notes-delete-button"
      onClick={handleClick}
    >
      <Icon iconName="TrashDelete" className="delete-icon" fill="#5A5A5A" />
    </ListItemButton>
  );
};

const menuOptions = [
  {
    name: 'Add Note',
    icon: (
      <Icon
        iconName="NewWindow"
        customSize="12px"
        fill="#FFFFFF"
        className="newWindowIcon"
      />
    ),
  },
  {
    name: 'View All',
    icon: (
      <Icon
        iconName="View"
        customSize="12px"
        fill="#FFFFFF"
        className="viewIcon"
      />
    ),
  },
];

// Public.

export const OSGPNotes = (props: OSGPNotesProps) => {
  const {
    className,
    apiConfig,
    noOfRecords = 5,
    initialSortValue = defaultInitialSortValue,
    sortOptions = defaultSortOptions,
  } = props;

  const [notes, setNotes]: any = useState([]);
  const [modalState, setModalState]: any = useState({
    openModal: null,
    listItemData: null,
    setListItemState: null,
  });
  const [sortValue, setSortValue] = useState(initialSortValue);
  const [isGetError, setGetError] = useState(false);
  const [notification, setNotification] = useState<
    OSGPNotesTasksSubheaderProps['notification']
  >({
    key: 0,
    isActive: false,
    isError: false,
    message: null,
  });

  const listContainerRef = useRef<any>();
  const sortMetadata = useRef({
    isSortInitiated: false,
    previousSortValue: initialSortValue,
  });
  const errorListItemStateSetter = useRef(new Map());

  const fullClassName = getFullClassName(['osgp-notes', className]);
  const isDeleteModalOpen = modalState.openModal === modals.DELETE;
  const isFormModalOpen = modalState.openModal === modals.FORM;

  const handleMenuItemClick = (menuName: string) => {
    if (menuName === 'Add Note') {
      setModalState({
        openModal: modals.FORM,
        listItemData: null,
        setListItemState: null,
      });
      return;
    } else if (menuName === 'View All') {
      props.onViewAllClick?.();
    }
  };

  const handleAdd = () => {
    setModalState({
      openModal: modals.FORM,
      listItemData: null,
      setListItemState: null,
    });
  };

  const handleSort = (name: string, value: string) => {
    sortMetadata.current = {
      isSortInitiated: true,
      previousSortValue: sortValue,
    };

    setSortValue(value);
  };

  const handleSuccess = (type: 'add' | 'delete' | 'update') => {
    let messageKey = 'addSuccess';

    if (type === 'delete') {
      // Reset delete error.
      modalState.setListItemState?.({ isDeleteError: false });
      messageKey = 'closeSuccess';
    } else if (type === 'update') {
      messageKey = 'updateSuccess';
    }

    // Show success notification.
    setNotification((prev) => ({
      isActive: true,
      key: prev.key + 1,
      message: NOTIFICATION_MESSAGES[messageKey],
      isError: false,
    }));

    // Get new set of notes.
    getNotes();
  };

  const handleDeleteFailure = () => {
    // Show item level error message.
    modalState.setListItemState?.((prev: any) => ({
      ...prev,
      isDeleteError: true,
    }));

    // Show failure notification.
    setNotification((prev) => ({
      isActive: true,
      key: prev.key + 1,
      message: NOTIFICATION_MESSAGES.error,
      isError: true,
    }));
  };

  const handleModalClose = () => {
    // Close the open modal.
    setModalState({
      openModal: null,
      listItemData: null,
      setListItemState: null,
    });
  };

  const DeleteActionWithProps = useCallback((deleteActionProps: any) => {
    return (
      <DeleteAction {...deleteActionProps} setModalState={setModalState} />
    );
  }, []);

  const ListItemWithProps = useCallback((listItemProps: any) => {
    return (
      <OSGPNoteListItem
        {...listItemProps}
        errorListItemStateSetter={errorListItemStateSetter}
        setModalState={setModalState}
      />
    );
  }, []);

  const getNotes = useCallback(() => {
    const { isSortInitiated, previousSortValue } = sortMetadata.current;

    // If sort is initiated and previous sort is same as the current one,
    // it means the sort failed and sort state was restored, so return.
    if (isSortInitiated && previousSortValue === sortValue) {
      sortMetadata.current.isSortInitiated = false;
      return;
    }

    setGetError(false);
    apiCall({
      baseUrl: apiConfig.baseUrl,
      route: `/note?status=OPEN&size=${noOfRecords}&sort=${sortValue}&currentRoleName=${apiConfig.currentRoleName}`,
      method: 'get',
      headers: getHeaders(apiConfig),
    }).then(({ isError, json, status }) => {
      const { errorCode, errorMessage, _embedded } = json;

      if (isError || errorCode || errorMessage || status !== 200) {
        // If there was an error in the API call because of sort value
        // had changed, display error notification in subheader.
        if (isSortInitiated) {
          setNotification((prev) => ({
            ...prev,
            key: prev.key + 1,
            isActive: true,
            isError: true,
            message: NOTIFICATION_MESSAGES.error,
          }));
          // Restore previous sort value.
          setSortValue(previousSortValue);
          return;
        }

        setGetError(true);
        return;
      }

      // If API call was due to sort.
      if (isSortInitiated) {
        sortMetadata.current.isSortInitiated = false;

        // Reset scroll position.
        if (listContainerRef.current) {
          listContainerRef.current.scrollTop = 0;
        }
      }

      // Reset all the list items state which had an error.
      errorListItemStateSetter.current.forEach((value, key) => {
        value({});
        errorListItemStateSetter.current.delete(key);
      });

      // Update notes.
      setNotes(_embedded?.note ?? []);
    });
  }, [apiConfig, noOfRecords, sortValue]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return (
    <>
      <OSGPListCard
        header="Notes"
        subheader={
          isGetError ? null : (
            <OSGPNotesTasksSubheader
              notification={notification}
              onAdd={handleAdd}
              onSort={handleSort}
              setNotification={setNotification}
              sortOptions={sortOptions}
              sortValue={sortValue}
            />
          )
        }
        enableMenu
        menuOptions={menuOptions}
        menuClickEvent={handleMenuItemClick}
        className={fullClassName}
        listProps={{
          data: notes,
          dataKeyName: 'noteId',
          listItemTemplate: ListItemWithProps,
          secondaryAction: DeleteActionWithProps,
          height: notification.isActive ? '256px' : '286px',
          containerRef: listContainerRef,
        }}
      >
        {isGetError ? (
          <OSGPNotesTasksErrorLoading onReload={getNotes} />
        ) : !notes?.length ? (
          <OSGPNotesTasksEmpty type="Notes" />
        ) : null}
      </OSGPListCard>
      {isDeleteModalOpen && (
        <OSGPDeleteNoteModal
          apiConfig={apiConfig}
          listItemData={modalState.listItemData}
          onClose={handleModalClose}
          onFailure={handleDeleteFailure}
          onSuccess={handleSuccess}
        />
      )}
      {isFormModalOpen && (
        <OSGPNoteFormModal
          isOpen={isFormModalOpen}
          apiConfig={apiConfig}
          data={modalState.listItemData}
          onClose={handleModalClose}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};
