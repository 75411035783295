import { DynamicFormV2Props } from "@optum-osgp-temp/ui-dynamic-forms-engine";
import { getCurrentDate, compareDates } from "../../utilities/dates";
import { EMAIL_REGEX } from "../../utilities/regex";
import { tasksFormModal } from "./constants";

export const dateValidation = (date: string) => {
  const { month, day, year } = getCurrentDate();
  const currentDate = `${year}-${month}-${day}`;

  if (compareDates(date, currentDate) === 1) return false;

  return tasksFormModal.oldDateError;
};

export const emailValidation = (emailsString: string) => {
  const emails = emailsString.split(",");
  const isEmailsInvalid = emails.some((email) => {
    const isValid = email.match(EMAIL_REGEX) && !email.includes("..");
    return !isValid;
  });

  if (isEmailsInvalid) return tasksFormModal.invalidEmailError;

  return false;
};

export const handleValidate: DynamicFormV2Props['customValidate'] = (formData, errors) => {
  const errorList = [];

  if (formData.taskTitle && formData.taskTitle.length > 255) {
    errorList.push(["taskTitle", tasksFormModal.taskTitleLimitError])
  }

  if (formData.reminderEmails) {
    if (emailValidation(formData.reminderEmails)) {
      errorList.push([
        "reminderEmails",
        emailValidation(formData.reminderEmails),
      ]);
    }

    if (formData.reminderEmails.length > 1000) {
      errorList.push(["reminderEmails", tasksFormModal.sendReminderLimitError])
    }

    if (!formData.reminderDate) {
      errorList.push(["reminderDate", tasksFormModal.missingReminderDateError]);
    }
  }

  if (formData.reminderDate) {
    if (dateValidation(formData.reminderDate)) {
      errorList.push(["reminderDate", dateValidation(formData.reminderDate)]);
    }
  }

  errorList.forEach(([fieldName, errorMessage]: any) => {
    if (!errorMessage) return;
    errors[fieldName]?.addError(errorMessage);
  });

  return errors;
};
