// Libraries.

import React from 'react';
import {
    Icon,
    Button,
  } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { OSGPNotesTasksErrorLoadingProps } from './OSGPNotesTasksErrorLoadingProps';
import './styles.scss';

// Public.

export const OSGPNotesTasksErrorLoading = ({ onReload }: OSGPNotesTasksErrorLoadingProps) => (
    <div className="osgp-notes-tasks-error-loading">
      <Icon iconName="AlertFilled" />
      <span>Content could not load.</span>
      <span>
        Please{' '}
        <Button type="button" onPress={onReload} variant="inverse">
          Reload
        </Button>
      </span>
    </div>
  );
  