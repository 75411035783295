var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from "react";
import { Card } from "../OSGPUITKComponents";
import './styles.scss';
import { UITKButton, UITKNotification, UITKProgressBar } from "@optum-osgp-internal/osgp-uitk-component-lib";
export var OSGPFileUploadComponent = function (_a) {
    var maxFileSize = _a.maxFileSize, AcceptedFileFormats = _a.AcceptedFileFormats, AcceptedFileTypes = _a.AcceptedFileTypes, handleUpload = _a.handleUpload, otherProps = __rest(_a, ["maxFileSize", "AcceptedFileFormats", "AcceptedFileTypes", "handleUpload"]);
    var _b = useState([]), filesList = _b[0], setFilesList = _b[1];
    var _c = useState(false), noFilesSelected = _c[0], setNoFilesSelected = _c[1];
    var _d = useState(false), OnUploadPress = _d[0], setOnUploadPress = _d[1];
    var _e = useState(true), showOnready = _e[0], setshowOnready = _e[1];
    var _f = useState(true), loading = _f[0], setloading = _f[1];
    var _g = useState(false), showOnProgress = _g[0], setShowOnProgress = _g[1];
    var _h = useState(false), uploadMeaasge = _h[0], setUploadMeaasge = _h[1];
    var _j = useState(0), progress = _j[0], setProgress = _j[1];
    var _k = useState(false), complete = _k[0], setComplete = _k[1];
    var _l = useState({}), errors = _l[0], setErrors = _l[1];
    var _m = useState([]), duplicates = _m[0], setDuplicates = _m[1];
    var _o = useState([]), checkItems = _o[0], setCheckItems = _o[1];
    function fileType(fileFormat) {
        return AcceptedFileFormats.includes(fileFormat) ? true : false;
    }
    var progressInterval = useRef();
    var findDuplicate = function () {
        var fileNames = filesList.map(function (item) { return item.name; });
        var set = new Set(fileNames);
        var duplicate = fileNames.filter(function (item) {
            if (set.has(item)) {
                set.delete(item);
            }
            else {
                return item;
            }
        });
        return duplicate;
    };
    useEffect(function () {
        setErrors({});
        var duplicate = findDuplicate();
        setDuplicates(duplicate);
        filesList.map(function (item, i) {
            var error = false;
            if (!fileType(item.type) || item.size > maxFileSize || duplicate.includes(item.name)) {
                error = true;
            }
            setErrors(function (prev) {
                var _a;
                return __assign(__assign({}, prev), (_a = {}, _a[i] = error, _a));
            });
        });
        if (filesList.length > 0) {
            setNoFilesSelected(false);
        }
        else {
            OnUploadPress ? setNoFilesSelected(true) : "";
        }
        return function () { clearInterval(progressInterval.current); };
    }, [filesList]);
    var removeItem = function (index) {
        setFilesList(function (filesList) {
            return filesList.filter(function (_, i) { return i !== index; });
        });
        setCheckItems(function (checkItems) {
            return checkItems.filter(function (_, i) { return i !== index; });
        });
        var filesToUpload = filesList.map(function (item) {
            if (fileType(item.type) &&
                item.size <= maxFileSize &&
                !duplicates.includes(item.name)) {
                return item;
            }
        }).filter(Boolean);
        handleUpload(filesToUpload);
    };
    var progressBarConfig = {
        label: "Uploading...",
        helperText: "",
        progress: progress,
        initialLabel: "Ready to Upload(0 %)",
        complete: complete,
    };
    var handleFileChange = function (e) {
        setFilesList(function (filesList) {
            var arr = filesList.concat(__spreadArray([], e.target.files, true));
            return arr;
        });
        setShowOnProgress(false);
        var tempArr = [];
        setCheckItems(function (items) {
            tempArr = __spreadArray([], e.target.files, true).map(function (item) {
                return false;
            });
            return items.concat(tempArr);
        });
    };
    var dragOverHandler = function (ev) {
        ev.preventDefault();
    };
    var dropHandler = function (ev) {
        ev.preventDefault();
        setFilesList(function (filesList) {
            var arr = filesList.concat(__spreadArray([], ev.dataTransfer.files, true));
            return arr;
        });
        setShowOnProgress(false);
        var tempArr = [];
        setCheckItems(function (items) {
            tempArr = __spreadArray([], ev.dataTransfer.files, true).map(function (item) {
                return false;
            });
            return items.concat(tempArr);
        });
    };
    var onUpload = function () {
        if (filesList.length > 0) {
            var arr = [];
            arr = filesList.map(function (item) {
                return true;
            });
            setCheckItems(arr);
            setNoFilesSelected(false);
            setOnUploadPress(false);
            setShowOnProgress(true);
        }
        else {
            setOnUploadPress(true);
            setNoFilesSelected(true);
        }
        var filesToUpload = filesList.map(function (item) {
            if (fileType(item.type) &&
                item.size <= maxFileSize &&
                !duplicates.includes(item.name)) {
                return item;
            }
        }).filter(Boolean);
        handleUpload(filesToUpload);
        var x = 0;
        progressInterval.current = window.setInterval(function () {
            if (x < 100) {
                x = x + 10;
                setProgress(x);
            }
            else {
                setComplete(true);
                setUploadMeaasge(true);
                setloading(false);
                clearInterval(progressInterval.current);
            }
        }, 100);
    };
    var formatBytes = function (bytes, decimals) {
        if (decimals === void 0) { decimals = 2; }
        if (!+bytes)
            return '0 Bytes';
        var k = 1024;
        var dm = decimals < 0 ? 0 : decimals;
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, null,
            React.createElement("div", { className: "file-upload-wrapper" },
                React.createElement("label", { className: "upload-label" }, "Upload File "),
                React.createElement("div", { className: "no-of-files" },
                    "Accepted File types: ",
                    AcceptedFileTypes),
                Object.values(errors).filter(function (err) { return err == true; }).length > 0 ?
                    React.createElement(UITKNotification, { className: "notification-wrapper", variant: "error", id: "notification-error" },
                        Object.values(errors).filter(function (err) { return err == true; }).length,
                        ' ',
                        "of ",
                        filesList.length,
                        " ",
                        "file",
                        filesList.length > 1 ? "s" : "",
                        " have errors. See the list of files for error details")
                    : noFilesSelected
                        ? React.createElement(UITKNotification, { className: "notification-wrapper", variant: "error", id: "notification-error1" }, "No files selected")
                        : "",
                React.createElement("div", { className: "errormsg-active-".concat(Object.values(errors).filter(function (err) { return err == true; }).length > 0 ? true : noFilesSelected ? true : "false") },
                    React.createElement("input", { type: "file", id: "file-input", style: { display: 'none' }, onChange: handleFileChange, multiple: true }),
                    React.createElement("label", { htmlFor: "file-input", className: "file-input-label", style: {} }, "Select Files"),
                    React.createElement("div", { className: "file-status-msg" }, filesList && (filesList === null || filesList === void 0 ? void 0 : filesList.length) === 0 || noFilesSelected
                        ? "No Files Selected"
                        : Object.values(errors).filter(function (err) { return err == true; }).length > 0
                            ? "".concat(Object.values(errors).filter(function (err) { return err == true; }).length, " of \n                  ").concat(filesList.length, " file").concat(filesList.length > 0 ? "s" : "", " have errors. \n                  See the list of files for error details")
                            : uploadMeaasge && !loading
                                ? "Files are uploaded"
                                : "Files ready to upload")),
                React.createElement("div", { id: "drop_zone", onDrop: dropHandler, onDragOver: dragOverHandler },
                    filesList && filesList.length === 0 &&
                        React.createElement("div", { className: "drop_msg" },
                            React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                                React.createElement("path", { d: "M16.5 7.5V8.5H22V21.5H2V8.5H8V7.5H1V22.5H23V7.5H16.5Z", fill: "#C25608" }),
                                React.createElement("path", { d: "M11.5 3.10002V15H12.5V3.05002L15.15 5.65002L15.85 4.95002L12 1.15002L8.14999 5.05002L8.84999 5.75002L11.5 3.10002Z", fill: "#C25608" })),
                            React.createElement("div", null, "Place files on the drop area to upload")),
                    filesList.length > 0
                        ? React.createElement("div", null, filesList.map(function (item, i) {
                            return React.createElement("div", { key: i + 1, className: "progressbar-wrapper ".concat(!fileType(item.type)
                                    ? "true"
                                    : item.size > maxFileSize
                                        ? "true"
                                        : duplicates.includes(item.name)
                                            ? "true"
                                            : "") },
                                React.createElement("div", { className: "align-btn" },
                                    React.createElement("div", { className: "filename-wrapper" },
                                        React.createElement("div", { className: "filename" }, item.name ? item.name : ""),
                                        React.createElement("div", { className: "filesize" },
                                            "File size : ",
                                            item.size ? formatBytes(item.size) : 0)),
                                    React.createElement(UITKButton, { variant: "ghost", onPress: function () { return removeItem(i); } }, "Remove")),
                                !fileType(item.type)
                                    ? React.createElement("div", { className: "invalid-file" },
                                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" },
                                            React.createElement("path", { d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" })),
                                        React.createElement("span", { className: "text" }, "Invalid file type"))
                                    : item.size > maxFileSize
                                        ? React.createElement("div", { className: "invalid-file" },
                                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" },
                                                React.createElement("path", { d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" })),
                                            React.createElement("span", { className: "text" }, "File size exceeded"))
                                        : duplicates.includes(item.name)
                                            ? React.createElement("div", { className: "invalid-file" },
                                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" },
                                                    React.createElement("path", { d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" })),
                                                React.createElement("span", { className: "text" }, "Duplicate Files are not permitted"))
                                            : uploadMeaasge && !loading && checkItems[i]
                                                ? React.createElement("div", { className: "uploaded-msg" },
                                                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", width: "64px", height: "64px" },
                                                        React.createElement("path", { d: "M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z M7,12L3.48,8.48l1.414-1.414L7,9.172l4.71-4.71 l1.414,1.414L7,12z" })),
                                                    React.createElement("span", { className: "text" }, "Uploaded"))
                                                : showOnready && !showOnProgress
                                                    ? React.createElement("div", { className: "ready-to-upload" },
                                                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 128 128", width: "64px", height: "64px" },
                                                            React.createElement("path", { d: "M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 64 30 A 9 9 0 0 0 64 48 A 9 9 0 0 0 64 30 z M 64 59 C 59 59 55 63 55 68 L 55 92 C 55 97 59 101 64 101 C 69 101 73 97 73 92 L 73 68 C 73 63 69 59 64 59 z" })),
                                                        React.createElement("span", { className: "text" }, "Ready to upload"))
                                                    : showOnProgress && loading
                                                        ? React.createElement(UITKProgressBar, { config: progressBarConfig })
                                                        : "");
                        }))
                        : "",
                    React.createElement(UITKButton, { variant: "ghost", onPress: onUpload }, "Upload"))))));
};
