// Libraries.

import React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

// Dependencies.

import { DatePicker } from '@optum-osgp-temp/osgp-ui-component-lib';

// Private.

const PopperProps: any = {
  placement: 'bottom-start',
};

// Public

export const GridEditDateCell = ({
  id,
  field,
  value,
}: GridRenderEditCellParams<Date>) => {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <DatePicker
      value={value || null}
      onChange={handleChange}
      id={id}
      required={false}
      placeholder="MM/DD/YYYY"
      PopperProps={PopperProps}
    />
  );
};
