// Libraries.

import React, { createContext, useContext } from 'react';

// Dependencies.

import { GridExports } from './GridExports';
import { GridToolbarFilter } from './GridToolbarFilter';
import { GridToolbarProps } from './DataGridProps';

// Private.

const GridToolbarContext = createContext<GridToolbarProps>({});

// Public.

export const GridToolbarContextProvider = GridToolbarContext.Provider;

export const GridToolbar = () => {
  const {
    customBasicFilterLabels,
    disableToolbarFilter,
    disableAdvanceFilters,
  } = useContext(GridToolbarContext);

  return (
    <div className="osgp-data-grid-toolbar-container">
      {!disableToolbarFilter ? (
        <GridToolbarFilter
          disableAdvanceFilters={disableAdvanceFilters}
          customBasicFilterLabels={customBasicFilterLabels}
        />
      ) : null}
      <GridExports />
    </div>
  );
};
