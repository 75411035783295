var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useLayoutEffect, useState } from "react";
import { Button, Card, Icon, LoadingIndicator } from "../OSGPUITKComponents";
import './styles.scss';
import { Document, Page, pdfjs } from 'react-pdf';
var containerNodeClassName = "osgp-static-content-container";
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.js");
var PreviousPageIcon = function () { return (React.createElement(Icon, { iconName: "ArrowLeft", fill: "#002677" })); };
var NextPageIcon = function () { return (React.createElement(Icon, { iconName: "ArrowRight", fill: "#002677" })); };
export var OSGPStaticContentResolver = function (_a) {
    var className = _a.className, docType = _a.docType, docURL = _a.docURL, failureMessage = _a.failureMessage, loaderSize = _a.loaderSize, _b = _a.loaderText, loaderText = _b === void 0 ? "" : _b, pdfDocumentProps = _a.pdfDocumentProps, pdfPageProps = _a.pdfPageProps, otherProps = __rest(_a, ["className", "docType", "docURL", "failureMessage", "loaderSize", "loaderText", "pdfDocumentProps", "pdfPageProps"]);
    //Common states
    var _c = useState(true), success = _c[0], setSuccess = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    //Html states
    var _e = useState(""), htmlContent = _e[0], setHtmlContent = _e[1];
    //PDF states
    var _f = useState(), contentSource = _f[0], setContentSource = _f[1];
    var _g = useState(), numPages = _g[0], setNumPages = _g[1];
    var _h = useState(1), pageNumber = _h[0], setPageNumber = _h[1];
    var _j = useState(), pdfDocProps = _j[0], setPdfDocProps = _j[1];
    var _k = useState(), pdfPgProps = _k[0], setPdfPgProps = _k[1];
    useLayoutEffect(function () {
        var tempPageProps = pdfPageProps;
        if (!tempPageProps) {
            tempPageProps = {};
        }
        if (!(tempPageProps === null || tempPageProps === void 0 ? void 0 : tempPageProps.height)) {
            tempPageProps.height = 800;
        }
        if (!(tempPageProps === null || tempPageProps === void 0 ? void 0 : tempPageProps.scale)) {
            tempPageProps.scale = 1;
        }
        setPdfPgProps(tempPageProps);
    }, [pdfDocumentProps, pdfPageProps]);
    useLayoutEffect(function () {
        if (docType && docURL && docURL.trim().length > 0) {
            if (docType === "HTML") {
                setLoading(true);
                fetch(docURL, {
                    headers: {}
                })
                    .then(function (res) {
                    setLoading(false);
                    if (res.ok) {
                        setSuccess(true);
                        return res.text();
                    }
                    throw new Error(res.status);
                })
                    .then(function (res) {
                    setHtmlContent(res);
                }).catch(function (error) {
                    setLoading(false);
                    setSuccess(false);
                    console.log(error + " Server Error");
                    setHtmlContent(failureMessage ? failureMessage : "Failed to load content");
                });
            }
            else if (docType === "PDF") {
                setContentSource({ url: docURL });
            }
        }
        else {
            setHtmlContent("");
        }
    }, [docURL]);
    function onDocumentLoadSuccess(pages) {
        console.log("PDF Total no of pages  -- ", pages);
        setNumPages(pages === null || pages === void 0 ? void 0 : pages.numPages);
        setPageNumber(1);
        setLoading(false);
        setSuccess(true);
    }
    function onDocumentLoadError(error) {
        setLoading(false);
        setSuccess(false);
    }
    function changePage(offset) {
        setPageNumber(function (prevPageNumber) { return prevPageNumber + offset; });
    }
    function previousPage() {
        changePage(-1);
    }
    function nextPage() {
        changePage(1);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: "".concat(containerNodeClassName + (className ? (" " + className) : "")) },
            React.createElement(LoadingIndicator, { className: 'static-content-loader-comp', loading: loading, displayOverlay: true, size: loaderSize ? loaderSize : "m", centerSpinner: true, accessibilityText: "Loading, please wait...", loadingText: loaderText ? loaderText : "Loading, please wait..." },
                docType == 'HTML' && !loading && success &&
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlContent } }),
                docType == 'PDF' && contentSource && contentSource.url &&
                    React.createElement(React.Fragment, null,
                        React.createElement(Document, __assign({ file: contentSource, onLoadSuccess: onDocumentLoadSuccess, onLoadError: onDocumentLoadError, loading: "", error: failureMessage ? failureMessage : "The resource you are trying to load is unavailable. Please try again later or contact support." }, pdfDocumentProps),
                            React.createElement(Page, __assign({ pageNumber: pageNumber }, pdfPgProps))),
                        !loading && success &&
                            React.createElement("div", { className: "pdf-pagination" },
                                React.createElement("p", null,
                                    "Page ",
                                    pageNumber || (numPages ? 1 : '--'),
                                    " of ",
                                    numPages || '--'),
                                React.createElement("div", { className: "pdf-navigation" },
                                    React.createElement(Button, { className: "osgp-page-navigation-button sgs-alt", disabled: pageNumber <= 1, onPress: previousPage, "aria-label": "Previous Page" },
                                        React.createElement(PreviousPageIcon, null)),
                                    React.createElement(Button, { className: "osgp-page-navigation-button sgs-alt", disabled: pageNumber >= (numPages ? numPages : 1), onPress: nextPage, "aria-label": "Next Page" },
                                        React.createElement(NextPageIcon, null))))),
                !loading && !success && docType != 'PDF' &&
                    React.createElement("div", null,
                        React.createElement("span", { style: { 'color': 'red' } }, failureMessage ? failureMessage : "The resource you are trying to load is unavailable. Please try again later or contact support."))))));
};
