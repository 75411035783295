var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkboxes } from "@optum-osgp-temp/osgp-ui-component-lib";
import React from 'react';
export default function OSGPCheckboxes(props) {
    var selectValue = function (value, selected, all) {
        var at = all.indexOf(value);
        var updated = selected.slice(0, at).concat(value, selected.slice(at));
        return updated.sort(function (a, b) { return all.indexOf(a) > all.indexOf(b); });
    };
    var deselectValue = function (value, selected) {
        return selected.filter(function (item) { return item !== value; });
    };
    console.log("OSGPCheckboxes new rendered", props);
    var id = props.id, value = props.value, disabled = props.disabled, required = props.required, readonly = props.readonly, autofocus = props.autofocus, options = props.options, onBlur = props.onBlur, onFocus = props.onFocus, onChange = props.onChange, rawErrors = props.rawErrors, schema = props.schema, otherProps = __rest(props, ["id", "value", "disabled", "required", "readonly", "autofocus", "options", "onBlur", "onFocus", "onChange", "rawErrors", "schema"]);
    var enumOptions = options.enumOptions, enumDisabled = options.enumDisabled, inline = options.inline, additionalProps = options.additionalProps;
    var styles = inline
        ? { display: "inline-flex", width: "100%", flexWrap: "wrap", gap: '15px' }
        : { display: "inherit" };
    return (React.createElement("div", { style: styles }, enumOptions.map(function (option, index) {
        var checked = value.indexOf(option.value) !== -1;
        var itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        var isDisabled = false;
        if (disabled) {
            isDisabled = true;
        }
        else if (itemDisabled) {
            isDisabled = true;
        }
        var descriptionId = schema.description === undefined ? "" : "".concat(id, "-ht ");
        var labelId = schema.title === undefined ? "" : "".concat(id, "-lbl");
        return (React.createElement(Checkboxes, __assign({ id: "".concat(id, "_").concat(index), value: value, checked: checked, required: required, disabled: isDisabled, readOnly: readonly, autoFocus: autofocus, onBlur: function (event) { onBlur(id, event.target.checked); }, onFocus: function (event) { onFocus(id, event.target.checked); }, error: typeof rawErrors === "undefined" ? false : (rawErrors.toString().trim().length > 0 ? true : false), onChange: function (event) {
                var all = enumOptions.map(function (_a) {
                    var value = _a.value;
                    return value;
                });
                if (event.target.checked) {
                    onChange(selectValue(option.value, value, all));
                }
                else {
                    onChange(deselectValue(option.value, value));
                }
            } }, additionalProps, { "aria-labelledby": "".concat(id, "_").concat(index, "-lbl"), "aria-describedby": labelId + " " + descriptionId }),
            React.createElement("span", { id: "".concat(id, "_").concat(index, "-lbl") }, option.label)));
    })));
}
OSGPCheckboxes.defaultProps = {
    autofocus: false
};
