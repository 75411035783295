var remPattern = /^\d+(\.\d+)?rem$/;
var pxPattern = /^\d+(\.\d+)?px$/;
export function getCssVariableValue(variableName) {
    return getComputedStyle(document.documentElement)
        .getPropertyValue(variableName);
}
export function convertPxToRem(sizeInPx) {
    if (sizeInPx === 0) {
        return 0;
    }
    return sizeInPx / 16;
}
export function getSizeInRem(size) {
    if (size.match(remPattern)) {
        return Number(size.slice(0, -3));
    }
    else if (size.match(pxPattern)) {
        // remove the last 2 characters and convert to rem
        return convertPxToRem(Number(size.slice(0, -2)));
    }
    return undefined;
}
export function getSizeInPx(size) {
    if (size.match(remPattern)) {
        return Number(size.slice(0, -3)) * 16;
    }
    else if (size.match(pxPattern)) {
        return Number(size.slice(0, -2));
    }
    return undefined;
}
