var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Autocomplete, TextField, } from "@optum-osgp-temp/osgp-ui-component-lib";
import React, { useState } from "react";
import * as _ from "lodash";
import { filterOptionsHelper } from "./helper";
export default function OSGPAutocompleteAsync(props) {
    var _this = this;
    var _a, _b, _c, _d, _e;
    var _f = useState(props.value || ""), inputValue = _f[0], setInputValue = _f[1];
    var _g = useState(null), value = _g[0], setValue = _g[1];
    var _h = useState(false), open = _h[0], setOpen = _h[1];
    var _j = React.useState([]), options = _j[0], setOptions = _j[1];
    var loading = open && options.length === 0;
    var searchKey = ((_a = props.options) === null || _a === void 0 ? void 0 : _a.searchKey) || "label";
    var inputLabelKey = ((_b = props.options) === null || _b === void 0 ? void 0 : _b.inputLabelKey) || "label";
    var apiEndpoint = (_c = props.options) === null || _c === void 0 ? void 0 : _c.apiEndpoint;
    React.useEffect(function () {
        var active = true;
        if (!loading) {
            return undefined;
        }
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (active) {
                    fetchOptions();
                }
                return [2 /*return*/];
            });
        }); })();
        return function () {
            active = false;
        };
    }, [loading]);
    var fetchOptions = React.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var response, res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!apiEndpoint) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(apiEndpoint)];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    res = _a.sent();
                    setOptions(res);
                    return [2 /*return*/, res];
                case 4:
                    error_1 = _a.sent();
                    throw new Error(error_1.message);
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    React.useEffect(function () {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    // For highlighting the matching text
    function highlightMatchingText(option, value) {
        var optionStringify = _.toString(option);
        var valueStringify = _.toString(value);
        var index = optionStringify.toLowerCase().indexOf(valueStringify.toLowerCase());
        if (index >= 0)
            return (React.createElement("span", { className: "options-list" },
                React.createElement("span", null, optionStringify.substring(0, index)),
                React.createElement("strong", { className: "matching-text" }, optionStringify.substring(index, index + valueStringify.length)),
                optionStringify.substring(index + valueStringify.length)));
        return option;
    }
    ;
    // Filtering the option list based on search key
    function filterOptions(options, state) {
        return filterOptionsHelper(options, state, searchKey);
    }
    ;
    // on reset event fire
    var clearInput = function () {
        setInputValue("");
        setValue(null);
    };
    //style of rendering the drop down box values
    function styledRenderOption(props, option, _a) {
        var inputValue = _a.inputValue;
        var optionKey = typeof option === "string"
            ? option
            : _.get(option, inputLabelKey);
        return (React.createElement("li", __assign({}, props, { key: _.uniqueId("option_") }), highlightMatchingText(optionKey, inputValue)));
    }
    ;
    // Input box render Input values
    var RenderInputProps = {
        endAdornment: (React.createElement(React.Fragment, null,
            inputValue ? (React.createElement("svg", { style: {
                    position: "absolute",
                    top: "50%",
                    right: "2.9rem",
                    transform: "translateY(-0.875rem)",
                    cursor: "pointer",
                }, onClick: clearInput, xmlns: "http://www.w3.org/2000/svg", width: "28", height: "28", viewBox: "0 0 28 28", fill: "none" },
                React.createElement("path", { d: "M9.62304 19.7335L14.0023 15.3508L18.3343 19.6861C18.7189 20.0711 19.3419 20.0711 19.7266 19.6861C20.0911 19.3198 20.0911 18.728 19.7266 18.3617L15.3588 13.9905L19.7151 9.63076C20.0868 9.25872 20.0868 8.65539 19.7151 8.28335C19.3376 7.90555 18.7232 7.90555 18.3457 8.28335L14.0009 12.6316L9.67184 8.29915C9.28573 7.91273 8.65847 7.91273 8.27236 8.29915C7.90921 8.66258 7.90921 9.25297 8.27236 9.6164L12.643 13.9905L8.3226 18.3143C7.93218 18.705 7.93218 19.3399 8.3226 19.7307C8.68144 20.0898 9.2642 20.0898 9.62304 19.7307V19.7335Z", fill: "#002677" }))) : null,
            React.createElement("span", { style: {
                    height: "24px",
                    border: "1px solid #E0E0E0",
                    position: "absolute",
                    right: "2.7rem",
                } }),
            React.createElement("svg", { onClick: function () { }, style: {
                    position: "absolute",
                    top: "50%",
                    right: "0.675rem",
                    transform: "translateY(-0.825rem)",
                    cursor: "pointer",
                }, xmlns: "http://www.w3.org/2000/svg", width: "28", height: "28", viewBox: "0 0 28 28", fill: "none" },
                React.createElement("g", { clipPath: "url(#clip0_29989_4886)" },
                    React.createElement("path", { d: "M17.2089 15.8334H16.4847L16.2281 15.5859C17.3281 14.3026 17.8964 12.5518 17.5847 10.6909C17.1539 8.14259 15.0272 6.10759 12.4606 5.79592C8.58307 5.31925 5.31974 8.58258 5.79641 12.4601C6.10807 15.0268 8.14307 17.1534 10.6914 17.5843C12.5522 17.8959 14.3031 17.3276 15.5864 16.2276L15.8339 16.4843V17.2084L19.7297 21.1043C20.1056 21.4801 20.7197 21.4801 21.0956 21.1043C21.4714 20.7284 21.4714 20.1143 21.0956 19.7384L17.2089 15.8334ZM11.7089 15.8334C9.42641 15.8334 7.58391 13.9909 7.58391 11.7084C7.58391 9.42592 9.42641 7.58342 11.7089 7.58342C13.9914 7.58342 15.8339 9.42592 15.8339 11.7084C15.8339 13.9909 13.9914 15.8334 11.7089 15.8334Z", fill: "#3967D8" })),
                React.createElement("defs", null,
                    React.createElement("clipPath", { id: "clip0_29989_4886" },
                        React.createElement("rect", { width: "28", height: "28", fill: "white" })))))),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocomplete, { id: props.id || "autocomplete-async", sx: { width: ((_d = props.schema) === null || _d === void 0 ? void 0 : _d.width) ? (_e = props.schema) === null || _e === void 0 ? void 0 : _e.width : "100%" }, disablePortal: true, value: value || null, filterOptions: filterOptions, inputValue: inputValue || "", getOptionLabel: function (option) {
                return typeof option === "string"
                    ? option
                    : _.get(option, inputLabelKey);
            }, onInputChange: function (e, value) {
                setInputValue(value);
                props.onChange(value);
            }, onChange: function (e, value) {
                setValue(value);
            }, onBlur: function (event) { return props.onBlur(props.id, value); }, onFocus: function (event) { return props.onFocus(props.id, value); }, options: options, loading: loading, renderOption: styledRenderOption, onOpen: function (e) { return setOpen(true); }, onClose: function (e) { return setOpen(false); }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { required: props.required, disabled: props.disabled, className: "roundedInput ".concat(open ? "roundedInputModified hideBorderBottom" : ""), placeholder: "Search...", size: "small", InputProps: __assign(__assign({}, params.InputProps), RenderInputProps) }))); } })));
}
