var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { TextInput } from "@optum-osgp-temp/osgp-ui-component-lib";
import { useRef } from "react";
export default function OSGPPhoneNumber(props) {
    var _a;
    var uiOptions = (_a = props.uiSchema) === null || _a === void 0 ? void 0 : _a["ui:options"];
    var ariaLabels = {};
    var _b = useState(props.value || ""), value = _b[0], setValue = _b[1];
    var ref = useRef(null);
    var formatNumber = function (value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value)
            return value;
        // clean the input for any non-digit values.
        // const ssn = value.replace(/[^\d]/g, '');
        var number = value.replace(/\D+/g, "");
        // numberLength is used to know when to apply our formatting for the number
        var numberLength = number.length;
        // we need to return the value with no formatting if its less then four digits
        if (numberLength <= 3)
            return number;
        // if numberLength is greater than 4 and less the 6 we start to return
        // the formatted number
        if (numberLength < 7) {
            return "(".concat(number.slice(0, 3), ")-").concat(number.slice(3));
        }
        // finally, if the numberLength is greater then 6, we add the last
        // bit of formatting and return it.
        return "(".concat(number.slice(0, 3), ")-").concat(number.slice(3, 6), "-").concat(number.slice(6, 10));
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "wrapper-ssn" },
            React.createElement(TextInput, __assign({ id: props === null || props === void 0 ? void 0 : props.id, value: value, required: props === null || props === void 0 ? void 0 : props.required, disabled: props === null || props === void 0 ? void 0 : props.disabled, readOnly: props === null || props === void 0 ? void 0 : props.readonly, autoFocus: props === null || props === void 0 ? void 0 : props.autofocus, placeholder: props === null || props === void 0 ? void 0 : props.placeholder, onBlur: function (event) { props.onBlur(props.id, event.target.value); }, onFocus: function (event) { props.onFocus(props.id, event.target.value); }, ref: ref, onChange: function (event) {
                    var value = event.target.value.replace(/\D+/g, "");
                    if (value.charAt(0) == 0)
                        value = value.slice(1, value.length);
                    var formattedValue = formatNumber(value);
                    setValue(formattedValue);
                    props.onChange(formattedValue);
                }, error: typeof props.rawErrors === "undefined" ? "" : props.rawErrors.toString() }, ariaLabels, uiOptions))));
}
