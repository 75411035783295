var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MUIThemeProvider } from "@optum-osgp-internal/osgp-mui-component-lib";
import { UITKToolKit } from "@optum-osgp-internal/osgp-uitk-component-lib";
import React from "react";
import getMUITheme from "../../themes/MUIOptumTheme";
import { segregateProps } from "../../utils/themeUtils";
// import { optumSolas } from "../../themes";
import { optum } from "../OSGPUITKComponents";
var defaultTheme = optum;
var OSGPThemeProvider = /** @class */ (function (_super) {
    __extends(OSGPThemeProvider, _super);
    function OSGPThemeProvider(props) {
        return _super.call(this, props) || this;
    }
    OSGPThemeProvider.prototype.render = function () {
        var themeProviderProps = segregateProps(this.props);
        var currentTheme = themeProviderProps.currentTheme ? themeProviderProps.currentTheme : defaultTheme;
        var otherProps = themeProviderProps.otherProps;
        var MUIOptumTheme = getMUITheme(currentTheme);
        return React.createElement(React.Fragment, null,
            React.createElement(UITKToolKit, __assign({ theme: currentTheme }, otherProps),
                React.createElement(MUIThemeProvider, { theme: MUIOptumTheme }, this.props.children)));
    };
    return OSGPThemeProvider;
}(React.Component));
export default OSGPThemeProvider;
