// Libraries.

import React, { useCallback, useState, useRef } from "react";
import {
  DynamicForm,
  DynamicFormV2Props
} from "@optum-osgp-temp/ui-dynamic-forms-engine";
import { Dialog, Button, Icon } from "@optum-osgp-temp/osgp-ui-component-lib";

// Dependencies.

import { addNoteModal } from "../OSGPNotes/constants";
import { OSGPNoteFormModalProps } from "./OSGPNoteFormModalProps";
import { apiCall, getHeaders } from "../../utilities/serviceUtils";
import { transformErrors } from "../../utilities/validations";
import { globalConstants } from "../../constants/globalConstants";
import { OSGPNotificationBanner } from "../OSGPNotificationBanner/OSGPNotificationBanner";
import { OSGPLoader } from "../OSGPLoader/OSGPLoader";
import "./styles.scss";

// Private.

const getFormData = (data: any) => ({
  noteTitle: data.noteTitle,
  noteDescription: data.noteDescription || "",
});

const handleValidate: DynamicFormV2Props['customValidate'] = (formData, errors) => {
  if (formData.noteTitle && formData.noteTitle.length > 255) {
    errors.noteTitle?.addError(addNoteModal.noteTitleLimitError);
  }
  return errors;
};

const schema: DynamicFormV2Props['schema'] = {
  type: "object",
  properties: {
    noteTitle: {
      type: "string",
      title: "Title",
    },
    noteDescription: {
      type: "string",
      title: "Description",
      maxLength: 2000,
    },
  },
  required: ["noteTitle"],
};

const uiSchema: DynamicFormV2Props['uiSchema'] = {
  noteTitle: {
    classNames: "title-input",
  },
  noteDescription: {
    "ui:widget": "textarea",
    "ui:options": {
      maxLength: 2000,
    },
    classNames: "description-input",
  },
};

const formContext: DynamicFormV2Props['formContext'] = {
  showErrorIcon: true,
};

// Public.

const OSGPNoteFormModal = (props: OSGPNoteFormModalProps) => {
  const { data, onClose, apiConfig, onSuccess, isOpen } = props;
  const isEditForm = data !== null;
  const [formData, setFormData] = useState(isEditForm ? getFormData(data) : {});
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const btnRef: any = useRef();

  const onSubmit = useCallback(
    ({ formData }: any) => {
      setLoading(true);
      setFormData(formData);

      apiCall({
        baseUrl: apiConfig.baseUrl,
        route: isEditForm
          ? `/note/${data.noteId}?currentRoleName=${apiConfig.currentRoleName}`
          : `/note?currentRoleName=${apiConfig.currentRoleName}`,
        method: isEditForm ? "put" : "post",
        body: {
          ...(isEditForm && data),
          noteTitle: formData.noteTitle,
          noteDescription: formData.noteDescription,
          userEmailId: "",
        },
        headers: getHeaders(apiConfig),
      }).then(({ isError, json }) => {
        const { errorCode, errorMessage, message, noteId } = json;
        
        setLoading(false);

        if (isError || errorCode || errorMessage) {
          setErrorMsg(errorMessage || globalConstants.serverError);
          return;
        }

        if (message?.toUpperCase() === "SUCCESS" || noteId) {
          onSuccess(isEditForm ? "update" : "add");
          onClose();
        }
      });
    },
    [apiConfig, isEditForm, data, onSuccess, onClose]
  );

  return (
    <Dialog
      className="osgp-add-note-modal-container"
      heading={addNoteModal[isEditForm ? 'editHeading' : 'heading']}
      open={isOpen}
      onClose={onClose}
    >
      <p className="osgp-notes-form-required-legend" aria-hidden>
        <Icon iconName="Asterisk" />
        Required
      </p>
      {loading ? <OSGPLoader loading={loading} /> : null}
      {errorMsg ? (
        <OSGPNotificationBanner
          type="error"
          toastMsg={errorMsg}
          setToastMsg={setErrorMsg}
        />
      ) : null}
      <DynamicForm
        enableVersion2
        schema={schema}
        uiSchema={uiSchema}
        formContext={formContext}
        formData={formData}
        autoComplete="off"
        liveValidate={false}
        transformErrors={transformErrors}
        customValidate={handleValidate}
        showErrorList={false}
        onSubmit={onSubmit}
      >
        <div className="notes-btn-container">
          <Button className="submit-btn" type="submit" autoFocus>
            Submit
          </Button>
          <Button className="cancel-btn" variant="ghost" onPress={onClose}>
            Cancel
          </Button>
        </div>
      </DynamicForm>
    </Dialog>
  );
};

export default OSGPNoteFormModal;
