// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input{
    width: 150px;
    padding: 20px;
    height: 30px;
    margin-right: 110px;
}

.quotient{
    position:absolute;
    top: 75px;
    right:10px;
}

.submitButton{
    position:absolute;
    top: 110px;
    right:15px;
}

.datagrid{
    margin-top: 62px;
    overflow: auto !important;
    height: calc(80vh - 55px);
}

.body{
    overflow: auto;
}

.navbar{
    position: relative;
    z-index: 999;
    top: auto;
}

.hidden{
    display: none;
    visibility: hidden;
  }

.dropdown{
    position: relative;
    z-Index:1;
    height: 30px;
    width: 90px;
    justify-items: center;
}

.single-select-cell {
    display: flex;
    align-items: center;
  }

.cellStyle{
    position: relative;
}

.iconStyle{
    position: absolute;
    top: auto;
    right: auto;
    margin-right: 2px;
}

/* Add a custom CSS rule with higher specificity to override the inline height */   
.MuiDataGrid-root .MuiDataGrid-detailPanel {  
    height: auto !important;  
  } 

  @media only screen and (max-width: 768px) {
    .datagrid{
        height: 45vh;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/DataGrid.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;IACb,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;EACrB;;AAEF;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,iBAAiB;AACrB;;AAEA,gFAAgF;AAChF;IACI,uBAAuB;EACzB;;EAEA;IACE;QACI,YAAY;IAChB;EACF","sourcesContent":[".input{\n    width: 150px;\n    padding: 20px;\n    height: 30px;\n    margin-right: 110px;\n}\n\n.quotient{\n    position:absolute;\n    top: 75px;\n    right:10px;\n}\n\n.submitButton{\n    position:absolute;\n    top: 110px;\n    right:15px;\n}\n\n.datagrid{\n    margin-top: 62px;\n    overflow: auto !important;\n    height: calc(80vh - 55px);\n}\n\n.body{\n    overflow: auto;\n}\n\n.navbar{\n    position: relative;\n    z-index: 999;\n    top: auto;\n}\n\n.hidden{\n    display: none;\n    visibility: hidden;\n  }\n\n.dropdown{\n    position: relative;\n    z-Index:1;\n    height: 30px;\n    width: 90px;\n    justify-items: center;\n}\n\n.single-select-cell {\n    display: flex;\n    align-items: center;\n  }\n\n.cellStyle{\n    position: relative;\n}\n\n.iconStyle{\n    position: absolute;\n    top: auto;\n    right: auto;\n    margin-right: 2px;\n}\n\n/* Add a custom CSS rule with higher specificity to override the inline height */   \n.MuiDataGrid-root .MuiDataGrid-detailPanel {  \n    height: auto !important;  \n  } \n\n  @media only screen and (max-width: 768px) {\n    .datagrid{\n        height: 45vh;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
