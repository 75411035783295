// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width:962px) {
  .canvas-container{
    position:relative;
    height: 77vh;
    width: 100%;
    overflow: auto;
    flex-basis: 50%;
  }
  .canvas{
    height: 480px;
    width: 500px;
    min-width: 40%;
    overflow: auto;
    flex-basis: 50%;
  }
  .button{
    position: absolute;
    top: 65px;
    right: 60%;
  }
  .loader{
    margin: 160px;
  }
  .inputFile{
    margin-bottom: 25px;
    margin-top: 5px;
    margin-right: 90px;
  }
}

@media only screen and (max-width: 768px) { 
  .canvas-container{
    position:relative;
    height: 680px;
    width: 40%;
    overflow: auto;
    flex-basis: 40%;
  }
  .canvas{
    position:absolute;
    height: 680px;
    width: 40%;
    overflow: auto;
    flex-basis: 50%;
  }
  
  .loader{
    margin: 160px;
  }
  
  .inputFile{
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 200px;
  }
}

.integrityDialog .MuiDialogContent-root {  
  overflow: hidden;  
} 
`, "",{"version":3,"sources":["webpack://./src/components/pdfViewer.css"],"names":[],"mappings":"AAAA;EACE;IACE,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,eAAe;EACjB;EACA;IACE,aAAa;IACb,YAAY;IACZ,cAAc;IACd,cAAc;IACd,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ;EACA;IACE,aAAa;EACf;EACA;IACE,mBAAmB;IACnB,eAAe;IACf,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,cAAc;IACd,eAAe;EACjB;EACA;IACE,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@media only screen and (min-width:962px) {\n  .canvas-container{\n    position:relative;\n    height: 77vh;\n    width: 100%;\n    overflow: auto;\n    flex-basis: 50%;\n  }\n  .canvas{\n    height: 480px;\n    width: 500px;\n    min-width: 40%;\n    overflow: auto;\n    flex-basis: 50%;\n  }\n  .button{\n    position: absolute;\n    top: 65px;\n    right: 60%;\n  }\n  .loader{\n    margin: 160px;\n  }\n  .inputFile{\n    margin-bottom: 25px;\n    margin-top: 5px;\n    margin-right: 90px;\n  }\n}\n\n@media only screen and (max-width: 768px) { \n  .canvas-container{\n    position:relative;\n    height: 680px;\n    width: 40%;\n    overflow: auto;\n    flex-basis: 40%;\n  }\n  .canvas{\n    position:absolute;\n    height: 680px;\n    width: 40%;\n    overflow: auto;\n    flex-basis: 50%;\n  }\n  \n  .loader{\n    margin: 160px;\n  }\n  \n  .inputFile{\n    margin-bottom: 5px;\n    margin-top: 5px;\n    margin-right: 200px;\n  }\n}\n\n.integrityDialog .MuiDialogContent-root {  \n  overflow: hidden;  \n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
