// Libraries.

import React, { useEffect } from 'react';
import { ListItemButton } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { getFormattedDate } from '../../utilities/dates';
import { getFullClassName } from '../../utilities/getFullClassName';
import { OSGPNotesTasksItemLevelError } from '../OSGPNotesTasksItemLevelError';
import { itemLevelCompleteError, modals } from './constants';

// Private.

const getIsOverDue = (dateString: string) => {
  const dateObj = new Date(dateString);
  return dateObj < new Date();
};

// Public.

export const OSGPTaskListItem = ({
  errorListItemStateSetter,
  listItemData,
  listItemState,
  setListItemState,
  setModalState,
}: any) => {
  const { dueDate, taskTitle } = listItemData;
  const { isCompleteError } = listItemState;
  const containerClassName = getFullClassName([
    'osgp-task-listitem-container',
    isCompleteError && 'osgp-task-listitem-container-with-error',
  ]);
  const isOverDue = getIsOverDue(dueDate);
  const taskDateClassName = getFullClassName([
    'osgp-task-date',
    isOverDue && 'osgp-task-date-due',
  ]);

  const handleClick = () => {
    setModalState({
      openModal: modals.FORM,
      listItemData,
      setListItemState,
    });
  };

  const handleErrorDismiss = () => {
    setListItemState((prev: any) => ({ ...prev, isCompleteError: false }));
  };

  useEffect(() => {
    // If there is no error in the list, ensure the parent does not track it.
    if (!isCompleteError) {
      errorListItemStateSetter.current.delete(listItemData.taskId);
    } else {
      // Else, hoist the state setter for the parent.
      errorListItemStateSetter.current.set(
        listItemData.taskId,
        setListItemState
      );
    }

    return () => {
      // Clear the hoisted state setter.
      errorListItemStateSetter.current.delete(listItemData.taskId);
    };
  }, [
    isCompleteError,
    errorListItemStateSetter,
    listItemData,
    setListItemState,
  ]);

  return (
    <div className={containerClassName}>
      <ListItemButton onClick={handleClick} className="osgp-task-listitem">
        <div className="osgp-task-title" title={taskTitle}>{taskTitle}</div>
        <div className={taskDateClassName}>
          Due on {getFormattedDate(dueDate)}
        </div>
      </ListItemButton>
      {isCompleteError ? (
        <OSGPNotesTasksItemLevelError
          message={itemLevelCompleteError}
          onDismiss={handleErrorDismiss}
        />
      ) : null}
    </div>
  );
};
