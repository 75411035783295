// Libraries.

import React, { useState, useRef } from 'react';
import {
  BaseMenu,
  BaseMenuItem,
  Button,
} from '@optum-osgp-temp/osgp-ui-component-lib';
import { Icon } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { OSGPSortByProps } from './OSGPNotesTasksSubheaderProps';

// Private.

const anchorOrigin: any = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin: any = {
  vertical: 'top',
  horizontal: 'right',
};

// Public.

export const OSGPSortBy = ({ value, options, onChange }: OSGPSortByProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef: any = useRef();

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div ref={anchorRef} className="osgp-notes-tasks-sort-by-container">
      <Button
        variant="ghost"
        size="s"
        className="osgp-notes-tasks-sort-by-button"
        onPress={handleOpen}
      >
        Sort By{' '}
        <Icon iconName={isOpen ? 'ArrowUp' : 'ArrowDown'} stroke="#3271E1" />
      </Button>
      <BaseMenu
        className="osgp-notes-tasks-sort-by-dropdown"
        anchorEl={anchorRef.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={isOpen}
        elevation={0}
        onClose={handleClose}
      >
        {options.map((option) => {
          const isSelected = value === option.value;
          const handleClick = () => {
            onChange(option.name, option.value);
            setIsOpen(false);
          };

          return (
            <BaseMenuItem
              disableGutters
              key={option.value}
              selected={isSelected}
              onClick={handleClick}
            >
              {option.name}
            </BaseMenuItem>
          );
        })}
      </BaseMenu>
    </div>
  );
};
