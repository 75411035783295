// Libraries.

import React, { useRef, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { Button, TextInput } from '@optum-osgp-temp/osgp-ui-component-lib';

// Dependencies.

import { GridPaginationGoToProps } from './DataGridProps';

// Private.

let uniqueIdCounter = 1;

// Public.

export const GridPaginationGoTo = ({ pageCount }: GridPaginationGoToProps) => {
  const inputRef = useRef<any>(null);
  const apiRef = useGridApiContext();
  const [value, setValue] = useState('');
  const [id] = useState(
    () => `osgp-data-grid-go-to-input-${uniqueIdCounter++}`
  );
  const numericValue = Number(value);
  const isSubmitDisabled = numericValue < 1 || numericValue > pageCount;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    apiRef.current.setPage(numericValue - 1);
    setValue('');
    inputRef.current?.focus();
  };

  return (
    <form className="osgp-data-grid-pagination-go-to" onSubmit={handleSubmit}>
      <label htmlFor={id}>Go To Page</label>
      <TextInput
        inputRef={inputRef}
        id={id}
        value={value}
        type="number"
        onChange={handleChange}
        min="1"
      />
      <Button variant="alternative" type="submit" disabled={isSubmitDisabled}>
        Go
      </Button>
    </form>
  );
};
