// Libraries.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState } from 'react';
import { MUIListItem, MUIList, } from '@optum-osgp-internal/osgp-mui-component-lib';
// Private.
var Item = function (_a) {
    var listItemProps = _a.listItemProps, ListItemTemplate = _a.listItemTemplate, GivenSecondaryAction = _a.secondaryAction, listItemData = _a.listItemData, index = _a.index, _b = _a.initialListItemState, initialListItemState = _b === void 0 ? {} : _b;
    var _c = useState(function () {
        // If initialListItemState is a function, invoke and get the initial state.
        if (typeof initialListItemState === 'function') {
            return initialListItemState(listItemData, index);
        }
        // Else, return the initial state.
        return initialListItemState;
    }), listItemState = _c[0], setListItemState = _c[1];
    // If secondary action component is given, invoke it.
    var secondaryAction = GivenSecondaryAction ? (React.createElement(GivenSecondaryAction, { index: index, listItemData: listItemData, listItemState: listItemState, setListItemState: setListItemState })) : null;
    // Output the markup.
    return (React.createElement(MUIListItem, __assign({}, listItemProps, { secondaryAction: secondaryAction }),
        React.createElement(ListItemTemplate, { index: index, listItemData: listItemData, listItemState: listItemState, setListItemState: setListItemState })));
};
// Public.
export var OSGPList = function (_a) {
    var containerClassName = _a.containerClassName, containerRef = _a.containerRef, data = _a.data, dataKeyName = _a.dataKeyName, height = _a.height, _b = _a.initialListItemState, initialListItemState = _b === void 0 ? {} : _b, listItemProps = _a.listItemProps, listItemTemplate = _a.listItemTemplate, secondaryAction = _a.secondaryAction, sx = _a.sx, otherProps = __rest(_a, ["containerClassName", "containerRef", "data", "dataKeyName", "height", "initialListItemState", "listItemProps", "listItemTemplate", "secondaryAction", "sx"]);
    // If height is given, use that to limit the list height and add a scroll bar.
    var containerDivStyle = useMemo(function () {
        return height
            ? {
                height: height,
                overflowY: 'auto'
            }
            : {};
    }, [height]);
    // Output the markup.
    return (React.createElement("div", { ref: containerRef, className: "osgp-list ".concat(containerClassName ? containerClassName : ''), style: containerDivStyle },
        React.createElement(MUIList, __assign({ sx: sx }, otherProps), data.map(function (listItemData, index) { return (React.createElement(Item, { listItemProps: listItemProps, listItemTemplate: listItemTemplate, key: dataKeyName ? listItemData[dataKeyName] : index, index: index, listItemData: listItemData, secondaryAction: secondaryAction, initialListItemState: initialListItemState })); }))));
};
